
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Auth/AuthContext'; // Adjust the path based on your file structure
import RequireAuth from './components/Auth/RequireAuth'; // Adjust the path
import 'react-tooltip/dist/react-tooltip.css';
import SignIn from './components/Auth/SignIn';
import Header from './components/Header/Header'; 
import Dashboard from './Pages/Dashboard';
import MyCalendar from './Pages/Tasks/MyCalendar';
import CreateStudentForm from './Pages/CreateUserform/CreateStudentForm.js';
import CreateTeacherForm from './Pages/CreateUserform/CreateTeacherForm.js';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Teacher from './Pages/Teacher/Teacher';
import Subject from './Pages/Subject/Subject';
import ClassDetails from './Pages/Class/ClassDetails';
import ClassDetailsStudent from './Pages/Class/ClassDetailsStudent.js';
import MyPerformance from './Pages/Student/MyPerformance.js';
import CurrentClasses from './Pages/Class/CurrentClasses';
import AllClasses from './Pages/Class/AllClasses.js';
import Classroom from './Pages/Classroom/Classroom';
import UserControlPage from './Pages/UserControlPage/UserControlPage';
import ChangePasswordPage from './Pages/UserControlPage/ChangePasswordPage';
import AdminPanel from './Pages/AdminPanel/AdminPanel';
import AnnouncementList from './Pages/Announcement/AnnouncementList.js';
import TeacherDashboard from './Pages/Teacher/TeacherDashboard';
import StudentDashboard from './Pages/Student/StudentDashboard';
import Registrations from './Pages/Registration/Registrations';
import TeacherDashboardAdmin from './Pages/Teacher/TeacherDashboardAdmin.js';
import StudentDashboardAdmin from './Pages/Student/StudentDashboardAdmin.js';
import Reports from './Pages/Report/Reports.js';
import Accounting from './Pages/Accounting/Accounting.js';
import AdminDashboard from './Pages/AdminDashboard';
import TeacherHours from './Pages/Teacher/TeacherHours.js';
import TeacherHoursAdmin from './Pages/Teacher/TeacherHoursAdmin.js';
import TeacherDetailsPage from './Pages/Teacher/TeacherDetailsPage.js';
import ChatPage from './Pages/Chat/ChatPage.js';
import Footer from './components/Footer/Footer.js';
import DoodlePage from './Pages/Doodle/DoodlePage.js';



function App() {
  const [userData, setUserData] = useState({});
  const navigate = useNavigate(); 
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  // This function updates the userData state
  const handleSetUserData = (data) => {
    setUserData(data);
  };

  const handleSignOut = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/signin');
 };

  return (
    <AuthProvider>
      {isAuthenticated && <Header onSignOut={handleSignOut} />}
      {isAuthenticated && <Sidebar/>}
      {isAuthenticated && <Footer />}
      <Routes>
        <Route path="/signin" element={<SignIn setUserData={handleSetUserData} />} />
        <Route path="/dashboard" element={<RequireAuth><Dashboard userData={userData} /></RequireAuth>} />
        <Route path="/calendar" element={<RequireAuth><MyCalendar userData={userData} /></RequireAuth>} />
        <Route path="/announcement" element={<RequireAuth><AnnouncementList userData={userData} /></RequireAuth>} />
        <Route path="/createstudentform" element={<RequireAuth><CreateStudentForm userData={userData} /></RequireAuth>} />
        <Route path="/createteacherform" element={<RequireAuth><CreateTeacherForm userData={userData} /></RequireAuth>} />
        <Route path="/teacher" element={<RequireAuth><Teacher userData={userData} /></RequireAuth>} />
        <Route path="/subject" element={<RequireAuth><Subject userData={userData} /></RequireAuth>} />
        <Route path="/classdetails/:groupid" element={<RequireAuth><ClassDetails userData={userData} /></RequireAuth>} />
        <Route path="/classdetailsstudent/:groupid" element={<RequireAuth><ClassDetailsStudent /></RequireAuth>} />
        <Route path="/myperformance" element={<RequireAuth><MyPerformance /></RequireAuth>} />
        <Route path="/currentclasses" element={<RequireAuth><CurrentClasses userData={userData} /></RequireAuth>} />
        <Route path="/allclasses" element={<RequireAuth><AllClasses userData={userData} /></RequireAuth>} />
        <Route path="/classroom" element={<RequireAuth><Classroom userData={userData} /></RequireAuth>} />
        <Route path="/usercontrolpage" element={<RequireAuth><UserControlPage userData={userData} /></RequireAuth>} />
        <Route path="/adminpanel" element={<RequireAuth><AdminPanel /></RequireAuth>} />
        <Route path="/teacher-dashboard/:id" element={<RequireAuth><TeacherDashboard /></RequireAuth>} />
        <Route path="/student-dashboard/:id" element={<RequireAuth><StudentDashboard /></RequireAuth>} />
        <Route path="/teacher-dashboard-admin/:id" element={<RequireAuth><TeacherDashboardAdmin /></RequireAuth>} />
        <Route path="/student-dashboard-admin/:id" element={<RequireAuth><StudentDashboardAdmin /></RequireAuth>} />
        <Route path="/registrations" element={<RequireAuth><Registrations /></RequireAuth>} />
        <Route path="/accounting" element={<RequireAuth><Accounting /></RequireAuth>} />
        <Route path="/report" element={<RequireAuth><Reports /></RequireAuth>} />
        <Route path="/admindashboard" element={<RequireAuth><AdminDashboard userData={userData} /></RequireAuth>} />
        <Route path="/teacher-hours" element={<RequireAuth><TeacherHours userData={userData} /></RequireAuth>} />
        <Route path="/teacher-hours-admin" element={<RequireAuth><TeacherHoursAdmin userData={userData} /></RequireAuth>} />
        <Route path="/teacher-details/:teacherId" element={<RequireAuth><TeacherDetailsPage /></RequireAuth>} />
        <Route path="/chat" element={<RequireAuth><ChatPage /></RequireAuth>} />
        <Route path="/doodle" element={<RequireAuth><DoodlePage /></RequireAuth>} />
        <Route path="/change-password" element={<RequireAuth><ChangePasswordPage /></RequireAuth>} />
        <Route path="/" element={<SignIn setUserData={handleSetUserData} />} />
      </Routes>
      
    </AuthProvider>
  );
}

export default App;
