import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt, faChalkboardTeacher, faBook, faVideo, faUserClock, faCalendar, faUser } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import StarRating from './StarRating';
import './Classes.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};

const formatTime = (timeString) => {
  const [hour, minute] = timeString.split(':');
  return `${hour}:${minute}`;
};

const formatMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const ClassDetailsStudent = () => {
  const { t } = useTranslation();
  const { groupid } = useParams();
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attendanceStatistics, setAttendanceStatistics] = useState({});
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [classComments, setClassComments] = useState({});
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [videoMeetingLinks, setVideoMeetingLinks] = useState({});
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [submittedRatings, setSubmittedRatings] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/class-groups/${groupid}`);
        setClassDetails(response.data);

        const { classgroup_ids, classes } = response.data;

        const fetchMessages = async () => {
          const token = localStorage.getItem('token');
          const messageResponses = await Promise.all(classgroup_ids.map(id => 
            axios.get(`${API_URL}/messages/${id}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const messages = messageResponses.flatMap(res => res.data);
          setMessages(messages);
        };

        const fetchAllClassComments = async () => {
          const token = localStorage.getItem('token');
          const commentResponses = await Promise.all(classes.map(cls =>
            axios.get(`${API_URL}/class-comments/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const commentsData = commentResponses.flatMap(res => res.data);
          const formattedComments = commentsData.reduce((acc, comment) => {
            acc[comment.classid] = comment.comment;
            return acc;
          }, {});
          setClassComments(formattedComments);
        };

        const fetchAllVideoMeetingLinks = async () => {
          const token = localStorage.getItem('token');
          const videoLinkResponses = await Promise.all(classes.map(cls =>
            axios.get(`${API_URL}/video-meeting-links/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const videoLinksData = videoLinkResponses.flatMap(res => res.data);
          const formattedVideoLinks = videoLinksData.reduce((acc, link) => {
            acc[link.classid] = link.video_meeting_link;
            return acc;
          }, {});
          setVideoMeetingLinks(formattedVideoLinks);
        };

        const fetchAttendanceStatistics = async () => {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/attendance/group/${groupid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAttendanceStatistics(response.data);
        };

        const fetchSubmittedRatings = async () => {
          try {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
            
            const ratingResponses = await Promise.all(classes.map(async cls => {
              try {
                const response = await axios.get(`${API_URL}/ratings/${userId}/${cls.classid}`, {
                  headers: { Authorization: `Bearer ${token}` },
                });
                return { classId: cls.classid, data: response.data };
              } catch (error) {
                if (error.response && error.response.status === 404) {
                  return { classId: cls.classid, data: null };
                }
                throw error;
              }
            }));
            
            const formattedRatings = ratingResponses.reduce((acc, res) => {
              if (res.data) {
                acc[res.classId] = res.data;
              }
              return acc;
            }, {});
        
            setSubmittedRatings(formattedRatings);
          } catch (error) {
            console.error('Error fetching submitted ratings:', error);
          }
        };
        

        fetchMessages();
        fetchAllClassComments();
        fetchAttendanceStatistics();
        fetchAllVideoMeetingLinks();
        fetchSubmittedRatings();
      } catch (error) {
        setError('Failed to fetch class details.');
      } finally {
        setLoading(false);
      }
    };

    const fetchRegisteredStudents = async () => {
      try {
        const response = await axios.get(`${API_URL}/registrations/classgroup/${groupid}`);
        setRegisteredStudents(response.data);
      } catch (error) {
        console.error('Error fetching registered students:', error);
      }
    };

    fetchClassDetails();
    fetchRegisteredStudents();
  }, [groupid, API_URL]);

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/messages`,
        { groupid, message: newMessage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewMessage('');
      setMessages(prevMessages => [
        ...prevMessages,
        { firstname: 'You', lastname: '', message: newMessage, timestamp: new Date().toISOString() }
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleRatingChange = (classid, rating) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [classid]: rating
    }));
  };

  const handleCommentChange = (classid, comment) => {
    setComments(prevComments => ({
      ...prevComments,
      [classid]: comment
    }));
  };

  const handleRatingSubmit = async (classid, teacherId) => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId'); 
      const rating = ratings[classid];
      const studentcomment = comments[classid]; // Use studentcomment instead of comment

  
      await axios.post(
        `${API_URL}/ratings`,
        { classid, teacherId, studentId: userId, rating, studentcomment }, // Send studentcomment to backend
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      confirmAlert({
        title: 'Success',
        message: 'Rating and student comment submitted successfully',
        buttons: [
          {
            label: 'OK',
            onClick: () => {}
          }
        ]
      });
  
      setSubmittedRatings(prevSubmittedRatings => ({
        ...prevSubmittedRatings,
        [classid]: { rating, studentcomment } 
      }));
    } catch (error) {
      console.error('Error submitting rating:', error);
      confirmAlert({
        title: 'Error',
        message: 'Failed to submit rating and student comment',
        buttons: [
          {
            label: 'OK',
            onClick: () => {}
          }
        ]
      });
    }
  };
  


  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!classDetails) return <p>No class details found.</p>;

  const attendanceData = [
    { name: 'Present', value: attendanceStatistics.present, color: '#36A2EB' },
    { name: 'Late', value: attendanceStatistics.late, color: '#FFCE56' },
    { name: 'Absent', value: attendanceStatistics.absent, color: '#FF6384' },
  ];

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="class-details-container">
      <div className="class-details-header">
          <h1>{classDetails.class_code}</h1>
          <h2><FontAwesomeIcon icon={faBook} className="icon"/>{classDetails.subjects?.join(', ')}</h2>
          <h2><FontAwesomeIcon icon={faChalkboardTeacher} className="icon"/>{classDetails.teachers?.join(', ')}</h2>
        </div>

        <div className="info-attendance-row">
          <div className="class-info-container">
            <h3 className="center-text">{t('classDetails')}</h3>
            <p><strong><FontAwesomeIcon icon={faBook} className="icon"/>{t('subject')}:</strong> {classDetails.subjects?.join(', ')}</p>
            <p><strong><FontAwesomeIcon icon={faChalkboardTeacher} className="icon"/>{t('teacher')}:</strong> {classDetails.teachers?.join(', ')}</p>
            <p><strong><FontAwesomeIcon icon={faCalendar} className="icon"/>{t('startDate')}:</strong> {formatDate(classDetails.start_date)}</p>
            <p><strong><FontAwesomeIcon icon={faClock} className="icon"/>{t('totalHours')}:</strong> {formatMinutesToHours(classDetails.total_hours)}</p>
            <p><strong><FontAwesomeIcon icon={faBook} className="icon"/>{t('bookDetails')}:</strong> {classDetails.book_details_materials}</p>
            <p><strong><FontAwesomeIcon icon={faUser} className="icon"/>{t('type')}:</strong> {classDetails.isprivate ? t('private') : t('group')}</p>
          </div>

          <div className="class-students-container">
            <h3 className="center-text">{t('registeredStudents')}</h3>
            <table>
              <tbody>
                {registeredStudents.map((student) => (
                  <tr key={student.student_id}>
                    <td className="center-text">{student.student_name} ({student.nickname})</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="info-attendance-row">
          <div className="class-messages-container">
            <h3 className="center-text">{t('messages')}</h3>
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={t('writeMessage')}
            />
            <button onClick={handleSendMessage}>{t('sendMessage')}</button>
            <div className="message-list">
              {messages.map((msg, index) => (
                <div key={index} className="message-item">
                  <p><strong>({msg.firstname} {msg.lastname}):</strong> {msg.message}</p>
                  <small className="message-time">{formatDate(msg.timestamp)} {t('at')} {formatDateTime(msg.timestamp)}</small>
                </div>
              ))}
            </div>
          </div>
          <div className="attendance-container">
            <h3>{t('classPerformance')}</h3>
            <div className="attendance-stats">
              <p>{t('present')}: {attendanceStatistics.presentPercentage}%</p>
              <p>{t('absent')}: {attendanceStatistics.absentPercentage}%</p>
              <p>{t('late')}: {attendanceStatistics.latePercentage}%</p>
            </div>
            <div className="attendance-chart" style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={attendanceData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                  >
                    {attendanceData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="class-schedule-container">
          {classDetails.classes?.map((slot, index) => (
            <div key={index} className="class-schedule-card">
              <h3>{t('class')} {index + 1} ({slot.classid})</h3>
              <h3>{slot.subject_name}</h3>
              <h4><FontAwesomeIcon icon={faChalkboardTeacher} /> {slot.teacher_names}</h4>
              <div className="video-meeting-link">
                {videoMeetingLinks[slot.classid] ? (
                  <div>
                    <a href={videoMeetingLinks[slot.classid]} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faVideo} /> {t('joinVideoMeeting')}
                    </a>
                  </div>
                ) : (
                  <p>{t('noVideoMeetingLink')}</p>
                )}
              </div>
              <p><FontAwesomeIcon icon={faCalendarAlt} /> {formatDate(slot.date)}</p>
              <p><FontAwesomeIcon icon={faClock} /> {formatTime(slot.schedule_time)}</p>
              <p><FontAwesomeIcon icon={faUserClock} /> {formatMinutesToHours(slot.schedule_hour)}</p>
              <p><FontAwesomeIcon icon={faBook} /> {slot.classroom_number}</p>
              <div className="class-comment">
                <textarea
                  placeholder={t('classComment')}
                  value={classComments[slot.classid] || ''}
                  readOnly
                  style={{ resize: 'none' }}
                />
              </div>
              {!submittedRatings[slot.classid] && (
                <>
                  <StarRating
                    rating={ratings[slot.classid] || 0}
                    onRatingChange={(rating) => handleRatingChange(slot.classid, rating)}
                    size="2x" // Update size to a valid value
                  />
                  <textarea
                    placeholder={t('writeComment')}
                    value={comments[slot.classid] || ''}
                    onChange={(e) => handleCommentChange(slot.classid, e.target.value)}
                  />
                  <button onClick={() => handleRatingSubmit(slot.classid, slot.teacher_id)}>
                    {t('submitRating')}
                  </button>
                </>
              )}
              {submittedRatings[slot.classid] && (
                <div className="submitted-feedback">
                  <p><strong>{t('thankyouforyourrating')}</strong></p>
                  <p><strong>{t('thankyouforyourcomment')}:</strong> {submittedRatings[slot.classid].studentcomment}</p>
                </div>
              )}

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRoleAccess(ClassDetailsStudent, ['superadmin', 'student']);
