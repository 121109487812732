import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Teacher.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';
import { useTranslation } from 'react-i18next';

function Teacher() {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTeachers(response.data);
        setLoading(false);
      } catch (error) {
        if (isTokenExpiredError(error)) {
          refreshToken().then(() => {
            fetchTeachers();
          }).catch(err => {
            console.error('Token refresh failed:', err);
            setError(t('sessionExpired'));
          });
        } else {
          console.error('Error fetching teachers:', error);
          setError(t('errorFetchingTeachers'));
        }
        setLoading(false);
      }
    };

    fetchTeachers();
  }, [API_URL, t]);

  if (loading) {
    return <p>{t('loading')}</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="teacher-container">
      <Sidebar />
      <Header />
      <div className="contact-container">
        <div className="teacher-header-tab"><h1>Our Teacher</h1></div>
        <div className="contact-grid">
          {teachers
            .sort((a, b) => a.userid - b.userid)
            .map((teacher) => (
              <div key={teacher.userid} className="contact">
                {teacher.photourl && (
                  <div className="teacher-image-container">
                    <img
                      src={teacher.photourl}
                      alt={`${teacher.firstname} ${teacher.lastname}`}
                      className="teacher-image"
                    />
                  </div>
                )}
                <div className="info-box">
                  {(teacher.firstname || teacher.lastname) && (
                    <h2 className="name">
                      {teacher.firstname} {teacher.lastname}
                    </h2>
                  )}
                  {teacher.email && (
                    <p className="email">
                      <FontAwesomeIcon icon={faEnvelope} /> {teacher.email}
                    </p>
                  )}
                  {teacher.specialize_subjects && (
                    <p className="specialize-subjects">{teacher.specialize_subjects}</p>
                  )}
                  {teacher.yearsexperience && (
                    <p>{t('yearsOfExperience')}: <b>{teacher.yearsexperience}</b></p>
                  )}
                  {teacher.bachelor_degree && (
                    <p>
                      <b>{teacher.bachelor_degree}</b> - {teacher.bachelor_school}
                    </p>
                  )}
                  {teacher.master_degree && (
                    <p>
                      <b>{teacher.master_degree}</b> - {teacher.master_school}
                    </p>
                  )}
                  {teacher.doctoral_degree && (
                    <p>
                      <b>{teacher.doctoral_degree}</b> - {teacher.doctoral_school}
                    </p>
                  )}
                  {teacher.schedule && <p>{teacher.schedule}</p>}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Teacher;
