import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; 
import SuccessModal from './SuccessModal';
import { useTranslation } from 'react-i18next';
import './CreateUserForm.css';

const initialFormData = {
  firstname: '',
  lastname: '',
  nickname: '',
  nationalid: '',
  date_of_birth: '',
  email: '',
  password: '',
  role: 'student',
  phone: '',
  address: '',
  currentEducationLevel: '',
  guardianName: '',
  guardianContact: '',
  schoolName: '',
};

function CreateStudentForm() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData(prevData => ({
      ...prevData,
      password: password
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
  
      const dataToSend = {
        ...formData,
        active: true,
      };
  
      await axios.post(`${API_URL}/users/create`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
  
      setFormData(initialFormData);
      setShowConfirmModal(false);
      setError(null);
    } catch (error) {
      console.error('Error creating user:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : error.message);
    }
  };
  

  return (
    <div className="form-page">
      <Sidebar />
      <Header />
      <div className="form-content">
        <h2>{t('form.add_new_student')}</h2>
        <form onSubmit={(e) => { e.preventDefault(); setShowConfirmModal(true); }} className="create-user-form">
          <div className="section">
            <h3>{t('form.student_details')}</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="firstname">{t('form.first_name')}</label>
                <input
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  placeholder={t('form.first_name')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastname">{t('form.last_name')}</label>
                <input
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  placeholder={t('form.last_name')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nickname">{t('form.nickname')}</label>
                <input
                  type="text"
                  name="nickname"
                  value={formData.nickname}
                  onChange={handleChange}
                  placeholder={t('form.nickname')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="nationalid">{t('form.national_id')}</label>
                <input
                  type="text"
                  name="nationalid"
                  value={formData.nationalid}
                  onChange={handleChange}
                  placeholder={t('form.national_id')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="date_of_birth">{t('form.date_of_birth')}</label>
                <input
                  type="date"
                  name="date_of_birth"
                  value={formData.date_of_birth}
                  onChange={handleChange}
                  placeholder={t('form.date_of_birth')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="currentEducationLevel">{t('form.current_education_level')}</label>
                <input
                  type="text"
                  name="currentEducationLevel"
                  value={formData.currentEducationLevel}
                  onChange={handleChange}
                  placeholder={t('form.current_education_level')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">{t('form.email')}</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder={t('form.email')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t('form.phone')}</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder={t('form.phone')}
                  required
                />
              </div>
            </div>
            <div className="form-group">
                <label htmlFor="password">{t('form.temporary_password')}</label>
                <div className="password-container">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder={t('form.temporary_password')}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                  <button className="generate-password-btn" type="button" onClick={generateRandomPassword}>
                    {t('form.generate_password')}
                  </button>
                </div>
              </div>
            <div className="form-group">
              <label htmlFor="address">{t('form.address')}</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder={t('form.address')}
                required
              />
            </div>
          </div>
          
          <div className="section">
            <h3>{t('form.parent_details')}</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="guardianName">{t('form.parent_name')}</label>
                <input
                  type="text"
                  name="guardianName"
                  value={formData.guardianName}
                  onChange={handleChange}
                  placeholder={t('form.parent_name')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="guardianContact">{t('form.parent_phone')}</label>
                <input
                  type="text"
                  name="guardianContact"
                  value={formData.guardianContact}
                  onChange={handleChange}
                  placeholder={t('form.parent_phone')}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="schoolName">{t('form.school_name')}</label>
              <input
                type="text"
                name="schoolName"
                value={formData.schoolName}
                onChange={handleChange}
                placeholder={t('form.school_name')}
                required
              />
            </div>
          </div>
          
          <button type="submit" className="submit-button">{t('form.create_student')}</button>
          {error && <div className="error-message">{t('form.error_message')}</div>}
        </form>
        {showConfirmModal && (
          <SuccessModal
            details={formData}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={handleSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(CreateStudentForm, ['admin', 'superadmin']);
