import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import './Registrations.css';

const RegistrationList = () => {
  const [studentClasses, setStudentClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStudentClass, setSelectedStudentClass] = useState(null);
  const [selectedClassId, setSelectedClassId] = useState('');
  const [allClasses, setAllClasses] = useState([]);
  const itemsPerPage = 100;
  const { t } = useTranslation();

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) {
      console.error('Invalid date:', dateString);
      return 'Invalid date';
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(t('locale'), options).format(date).toUpperCase();
  };
  
  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };

  const fetchRating = useCallback(async (studentId, classId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/ratings/${studentId}/${classId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      return {
        rating: response.data.rating,
        comment: response.data.comment,
      };
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return {
          rating: null,
          comment: 'No comment yet',
        };
      } else {
        console.error('Error fetching rating:', error);
      }
      return null;
    }
  }, [API_URL]);

  const fetchAttendanceAndRating = useCallback(async (studentClass) => {
    try {
      const token = localStorage.getItem('token');
      const attendanceResponse = await axios.get(`${API_URL}/attendance/${studentClass.class_id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const attendanceData = attendanceResponse.data.find(
        (att) => att.student_id === studentClass.student_id
      );

      const ratingData = await fetchRating(studentClass.student_id, studentClass.class_id);

      return {
        ...studentClass,
        attendance: attendanceData ? attendanceData.status : '',
        comment: attendanceData ? attendanceData.comment : '',
        teacher_rating: ratingData ? ratingData.rating : '',
        studentcomment: ratingData ? ratingData.comment : ''
      };
    } catch (error) {
      console.error('Error fetching attendance and rating:', error);
      return studentClass;
    }
  }, [API_URL, fetchRating]);

  const fetchStudentClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/student_classes`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const sortedClasses = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      return sortedClasses;
    } catch (error) {
      console.error('Error fetching student classes:', error);
      return [];
    }
  }, [API_URL]);

  const fetchFutureClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/futureclass`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setAllClasses(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching future classes:', error);
      return [];
    }
  }, [API_URL]);

  useEffect(() => {
    const fetchAllData = async () => {
      const studentClasses = await fetchStudentClasses();
  
      const updatedStudentClasses = await Promise.all(
        studentClasses.map(sc => fetchAttendanceAndRating(sc))
      );
  
      const futureClasses = await fetchFutureClasses();
  
      const allClassesCombined = [...updatedStudentClasses, ...futureClasses];
  
      const sortedClasses = allClassesCombined.sort((a, b) => new Date(b.date) - new Date(a.date));
  
      setStudentClasses(sortedClasses);
      setFilteredClasses(sortedClasses);
    };
  
    fetchAllData();
  }, [API_URL, fetchAttendanceAndRating, fetchFutureClasses, fetchStudentClasses]);

  useEffect(() => {
    
    const filtered = studentClasses.filter(studentClass =>
      studentClass.student_name ? studentClass.student_name.toLowerCase().includes(searchTerm.toLowerCase()) : false
    );
    setFilteredClasses(filtered);
    setCurrentPage(1);
  }, [searchTerm, studentClasses]);
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredClasses.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleChangeClassClick = (studentClass) => {
    setSelectedStudentClass(studentClass);
  };

  const handleClassChange = async () => {
    // Remove the check for the selected class in allClasses
  
    // Check if the student is already registered for the selected class
    const existingClass = studentClasses.find(
      (sc) => sc.student_id === selectedStudentClass.student_id && sc.class_id === selectedClassId
    );
  
    if (existingClass) {
      confirmAlert({
        title: t('Class Change Not Allowed'),
        message: t('The student is already registered for the selected class.'),
        buttons: [{ label: t('OK'), onClick: () => {} }],
      });
      return; // Stop further execution if the class is already registered
    }
  
    // Proceed with the API call to change the class
    confirmAlert({
      title: t('Confirm Class Change'),
      message: `${t('Are you sure you want to change the class for')} ${selectedStudentClass.student_name}?`,
      buttons: [
        {
          label: t('Yes'),
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.put(
                `${API_URL}/registrations/change_class`,
                {
                  id: selectedStudentClass.id,
                  class_id: selectedClassId,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
  
              // Update the state with the new class information
              setStudentClasses((prevClasses) =>
                prevClasses.map((sc) =>
                  sc.id === selectedStudentClass.id
                    ? {
                        ...sc,
                        class_id: selectedClassId,
                      }
                    : sc
                )
              );
              setFilteredClasses((prevClasses) =>
                prevClasses.map((sc) =>
                  sc.id === selectedStudentClass.id
                    ? {
                        ...sc,
                        class_id: selectedClassId,
                      }
                    : sc
                )
              );
              setSelectedStudentClass(null);
              confirmAlert({
                title: t('success'),
                message: t('The class has been successfully changed.'),
                buttons: [{ label: t('OK'), onClick: () => {} }],
              });
            } catch (error) {
              console.error('Error changing class:', error);
            }
          },
        },
        { label: t('No'), onClick: () => setSelectedStudentClass(null) },
      ],
    });
  };

  return (
    <div className="registration-list">
      <h1>{t('StudentClasses')}</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder={t('searchByName')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <table className="student-classes-table">
        <thead>
          <tr>
            <th>{t('id')}</th>
            <th>{t('student')}</th>
            <th>{t('classid')}</th>
            <th>{t('subject')}</th>
            <th>{t('date')}</th>
            <th>{t('hours')}</th>
            <th>{t('attendance')}</th>
            <th>{t('comments')}</th>
            <th>{t('rating')}</th>
            <th>{t('writeComment')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
        {currentItems.map((studentClass, index) => (
              <tr key={`${studentClass.id}-${index}`}>
              <td>{studentClass.id}</td>
              <td>{studentClass.student_name}</td>
              <td>{studentClass.class_id}</td>
              <td>{studentClass.subject_name}</td>
              <td>{formatDate(studentClass.date)}</td>
              <td>{formatMinutesToHours(studentClass.schedule_hour)}</td>
              <td>{studentClass.attendance}</td>
              <td>{studentClass.comment}</td>
              <td>
                    {studentClass.teacher_rating && typeof studentClass.teacher_rating === 'object'
                    ? studentClass.teacher_rating.teacher_rating.toString()  // Access the teacher_rating property
                    : ''}
                </td>
                <td>
                    {studentClass.teacher_rating && typeof studentClass.teacher_rating === 'object'
                    ? studentClass.teacher_rating.studentcomment  // Access the studentcomment property
                    : ''}
                </td>
              <td>
                <button className= "change-class" onClick={() => handleChangeClassClick(studentClass)}>{t('changeClass')}</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredClasses.length / itemsPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => paginate(i + 1)}
            className={`page-button ${i + 1 === currentPage ? 'active' : ''}`}
          >
            {i + 1}
          </button>
        ))}
      </div>

      {selectedStudentClass && (
        <div className="modal-overlay">
        <div className="modal-content">
          <h2>{t('changeClassFor')} {selectedStudentClass.student_name}</h2>
          <div>
            <label>{t('selectNewClass')}:</label>
            <select 
              value={selectedClassId} 
              onChange={(e) => setSelectedClassId(e.target.value)}
            >
              {allClasses.length > 0 ? (
                allClasses.map((cls) => (
                  <option key={cls.classid} value={cls.classid}>
                    ({cls.classid}) {cls.subject_name} - {formatDate(cls.date)} - {cls.schedule_hour}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  {t('No future classes available')}
                </option>
              )}
            </select>
          </div>
          <div className="button-group">
            <button onClick={handleClassChange} className="confirm-button">{t('confirm')}</button>
            <button onClick={() => setSelectedStudentClass(null)} className="cancel-button">{t('cancel')}</button>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default withRoleAccess(RegistrationList, ['admin', 'superadmin']);
