import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import './TeacherHours.css';
import TeacherPaymentReport from './TeacherPaymentReport';
import TutorClassLog from './TutorClassLog';
import { useTranslation } from 'react-i18next';

const TeacherHoursAdmin = () => {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('hours');
  const itemsPerPage = 50;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setTeachers(response.data);
      } catch (error) {
        console.error(t('errorFetchingTeachers'), error);
        setError(t('failedToFetchTeachers'));
      }
    };

    fetchTeachers();
  }, [API_URL, t]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredTeachers = teachers.filter(teacher =>
    teacher.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    teacher.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    teacher.userid.toString().includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredTeachers.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentTeachers = filteredTeachers.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderTabs = () => (
    <div className="tutor-tabs-container">
      <button
        className={`tutor-tab-button ${activeTab === 'hours' ? 'active' : ''}`}
        onClick={() => setActiveTab('hours')}
      >
        {t('teacherHours')}
      </button>
      <button
        className={`tutor-tab-button ${activeTab === 'payment' ? 'active' : ''}`}
        onClick={() => setActiveTab('payment')}
      >
        {t('paymentReport')}
      </button>
      <button
        className={`tutor-tab-button ${activeTab === 'logs' ? 'active' : ''}`}
        onClick={() => setActiveTab('logs')}
      >
        {t('tutorclasslogs')}
      </button>
    </div>
  );
  

  return (
    <div>
      <Sidebar />
      <Header />
      {renderTabs()}
      <div className="teacher-hours-container">
        {activeTab === 'hours' && (
          <div className="teacher-hours-content">
            <h1>{t('tutorHourlyRates')}</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="search-container">
              <input
                type="text"
                placeholder={t('searchByNameOrID')}
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
            </div>
            <table>
              <thead>
                <tr>
                  <th>{t('teacherID')}</th>
                  <th>{t('teacherName')}</th>
                  <th>{t('hourlyRate')}</th>
                </tr>
              </thead>
              <tbody>
                {currentTeachers.map(teacher => (
                  <tr key={teacher.userid}>
                    <td>{teacher.userid}</td>
                    <td>
                      <Link to={`/teacher-details/${teacher.userid}`}>
                        {teacher.firstname} {teacher.lastname}
                      </Link>
                    </td>
                    <td>{teacher.hoursrate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-container">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="page-button"
              >
                {t('previous')}
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="page-button"
              >
                {t('next')}
              </button>
            </div>
          </div>
        )}
        {activeTab === 'payment' && <TeacherPaymentReport />}
        {activeTab === 'logs' && <TutorClassLog />}
      </div>
    </div>
  );
};

export default withRoleAccess(TeacherHoursAdmin, ['superadmin']);
