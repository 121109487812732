import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

// Example data, you can adjust this to your actual data
const data = [
  { name: 'Awesome', value: 90, color: '#8dc572' },
  { name: 'Average', value: 5, color: '#FFCE56' },
  { name: 'Neutral', value: 3, color: '#FF6384' },
  { name: 'Below Average', value: 2, color: '#AA6F73' },
];

const CustomPieChart = () => {
  return (
    <PieChart width={200} height={200}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={80} // Adjust inner radius for the donut effect
        outerRadius={100} // Adjust outer radius to control size
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip />
      <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
        Performance
      </text>
    </PieChart>
  );
};

export default CustomPieChart;
