import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import './Teacher.css';
import { useTranslation } from 'react-i18next';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) {
    return 'Invalid Date';
  }
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};

const TeacherDetailsPage = () => {
  const { teacherId } = useParams();
  const { t } = useTranslation(); // useTranslation hook
  const [teacher, setTeacher] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tempMonth, setTempMonth] = useState(new Date().getMonth() + 1);
  const [tempYear, setTempYear] = useState(new Date().getFullYear());
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [hourlyRates, setHourlyRates] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [withholdingTax, setWithholdingTax] = useState(false); // New state for withholding tax
  const [showConfirmModal, setShowConfirmModal] = useState(false); // New state for confirmation modal
  const [confirmData, setConfirmData] = useState({}); // New state to hold data for confirmation
  
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchTeacher = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTeacher(response.data);
    } catch (error) {
      console.error(t('errorFetchingTeacher'), error);
    }
  }, [teacherId, API_URL, t]);

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClasses(response.data);
      filterClasses(response.data, selectedMonth, selectedYear);
    } catch (error) {
      console.error(t('errorFetchingClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  const fetchApprovedClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teacher-hours/${teacherId}/approved`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          period: `${selectedYear}-${selectedMonth < 10 ? '0' + selectedMonth : selectedMonth}`
        }
      });
      if (response.data.length > 0) {
        setIsApproved(true);
        setFilteredClasses(response.data);
        const rates = {};
        response.data.forEach(({ class_id, hourly_rate }) => {
          rates[class_id] = hourly_rate;
        });
        setHourlyRates(rates);
      } else {
        setIsApproved(false);
      }
    } catch (error) {
      console.error(t('errorFetchingApprovedClasses'), error);
    }
  }, [teacherId, selectedMonth, selectedYear, API_URL, t]);

  useEffect(() => {
    fetchTeacher();
    fetchClasses();
    fetchApprovedClasses();
  }, [fetchTeacher, fetchClasses, fetchApprovedClasses]);

  const filterClasses = (classes, month, year) => {
    const filtered = classes.filter(cls => {
      const classDate = new Date(cls.date);
      return classDate.getMonth() + 1 === month && classDate.getFullYear() === year;
    });
    setFilteredClasses(filtered);
  };

  const handleMonthChange = (event) => {
    const month = parseInt(event.target.value, 10);
    setTempMonth(month);
  };

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value, 10);
    setTempYear(year);
  };

  const handleSelect = () => {
    setSelectedMonth(tempMonth);
    setSelectedYear(tempYear);
    filterClasses(classes, tempMonth, tempYear);
  };

  const convertScheduleHourToDecimal = (scheduleHour) => {
    if (!scheduleHour) return 0;
    const [hours, minutes] = scheduleHour.split('h').map(part => part.trim().replace('m', ''));
    const hoursDecimal = parseInt(hours, 10) || 0;
    const minutesDecimal = parseInt(minutes, 10) / 60 || 0;
    return hoursDecimal + minutesDecimal;
  };

  const formatDecimalToHoursMinutes = (decimalHours) => {
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const handleApprove = () => {
    const totalAmountWithTax = withholdingTax ? totalAmount * 0.97 : totalAmount;
    setConfirmData({
      teacherName: `${teacher.firstname} ${teacher.lastname}`,
      period: `${selectedMonth}/${selectedYear}`,
      totalAmount: formatAmount(totalAmountWithTax),
    });
    setShowConfirmModal(true);
};

const confirmApproval = async () => {
  try {
    const token = localStorage.getItem('token');
    await axios.post(`${API_URL}/teacher-hours/${teacherId}/approve`, {
      period: `${selectedYear}-${selectedMonth < 10 ? '0' + selectedMonth : selectedMonth}`,
      approvedClasses: filteredClasses.map(cls => {
        const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
        return {
          classId: cls.classid,
          hourlyRate: hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
          hoursWorked: scheduleHourDecimal.toFixed(2)
        };
      }),
      totalAmount: confirmData.totalAmount,
      withholdingTaxApplied: withholdingTax // This should now be defined properly
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    setShowConfirmation(true);
    fetchApprovedClasses();
    setShowConfirmModal(false);
  } catch (error) {
    console.error(t('errorApprovingHours'), error);
  }
};



  const handleExportCSV = () => {
    const csvData = filteredClasses.map(cls => ({
      'Class Code': cls.class_code,
      'Class ID': cls.classid,
      'Subject Name': cls.subject_name,
      'Date': formatDate(cls.date),
      'Schedule Hour': cls.schedule_hour,
      'Hourly Rate': hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0),
      'Total Hours': convertScheduleHourToDecimal(cls.schedule_hour).toFixed(2),
      'Total Amount': formatAmount(convertScheduleHourToDecimal(cls.schedule_hour) * (hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0))),
      'Status': cls.status, // Add Status
      'Payout': cls.payout // Add Payout
    }));
    return csvData;
  };

  const handleRateChange = (classId, value) => {
    setHourlyRates({
      ...hourlyRates,
      [classId]: parseFloat(value) || 0
    });
  };

  const totalScheduleHours = filteredClasses.reduce((acc, cls) => acc + convertScheduleHourToDecimal(cls.schedule_hour), 0);
  const totalHours = filteredClasses.reduce((acc, cls) => acc + convertScheduleHourToDecimal(cls.schedule_hour), 0);
  const totalAmount = filteredClasses.reduce((acc, cls) => {
    const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
    const hourlyRate = hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0);
    return acc + (scheduleHourDecimal * hourlyRate);
  }, 0);

  const formatAmount = (amount) => {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="teacher-details-container">
        <div className="teacher-hours-content">
          <h1>{t('teacherDetails')}</h1>
          {teacher && (
            <>
              <h3>{teacher.firstname} {teacher.lastname}</h3>
              <p>{t('teacherID')}: {teacher.userid}</p>
              <p>{t('currentHourlyRate')}: {teacher.hoursrate}</p>
              <p><a href="#!">{t('seeAllPastPayments')}</a></p>
              <div className="datepicker">
                <label>
                  {t('month')}:
                  <select value={tempMonth} onChange={handleMonthChange}>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                      <option key={month} value={month}>{new Date(0, month - 1).toLocaleString('default', { month: 'long' })}</option>
                    ))}
                  </select>
                </label>
                <label>
                  {t('year')}:
                  <select value={tempYear} onChange={handleYearChange}>
                    {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </label>
                <button onClick={handleSelect}><FontAwesomeIcon icon={faSearch} /></button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>{t('classCode')}</th>
                    <th>{t('classID')}</th>
                    <th>{t('subjectName')}</th>
                    <th>{t('date')}</th>
                    <th>{t('totalHour')}</th>
                    <th>{t('hourlyRate')}</th>
                    <th>{t('calculateHours')}</th>
                    <th>{t('totalAmount')}</th>
                    <th>{t('confirmteach')}</th>
                    <th>{t('payout')}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClasses.map(cls => {
                    const scheduleHourDecimal = convertScheduleHourToDecimal(cls.schedule_hour);
                    const hourlyRate = hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0);
                    const totalAmount = scheduleHourDecimal * hourlyRate;
                    return (
                      <tr key={cls.classid}>
                        <td>{cls.class_code}</td>
                        <td>{cls.classid}</td>
                        <td>{cls.subject_name}</td>
                        <td>{formatDate(cls.date)}</td>
                        <td>{cls.schedule_hour}</td>
                        <td>
                          <input
                            type="number"
                            value={hourlyRates[cls.classid] || (teacher ? teacher.hoursrate : 0)}
                            onChange={(e) => handleRateChange(cls.classid, e.target.value)}
                            disabled={isApproved}
                          />
                        </td>
                        <td>{scheduleHourDecimal.toFixed(2)}</td>
                        <td>{isNaN(totalAmount) ? 'N/A' : formatAmount(totalAmount)}</td>
                        <td>{cls.status === 'approved' ? t('HOURSAPPROVED') : t('confirmpending')}</td> {/* Display Status */}
                        <td>{cls.payout === 'pending' ? t('Pending') : t('Paid')}</td> {/* Display Payout */}
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan="3"><strong>{t('total')}</strong></td>
                    <td></td>
                    <td><strong>{formatDecimalToHoursMinutes(totalScheduleHours)}</strong></td>
                    <td></td>
                    <td><strong>{totalHours.toFixed(2)}</strong></td>
                    <td><h3>{formatAmount(totalAmount)}</h3></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              {!isApproved && <button onClick={handleApprove}>{t('approveHoursAndPayment')}</button>}
              <CSVLink data={handleExportCSV()} filename={`approved_hours_${teacherId}_${selectedMonth}_${selectedYear}.csv`}>
                <button><FontAwesomeIcon icon={faFileExcel} /> {t('exportToCSV')}</button>
              </CSVLink>
              {showConfirmation && (
                <div className="confirmation-popup">
                  <p>{t('teacherHoursApprovalSaved')}</p>
                  <button onClick={() => setShowConfirmation(false)}>{t('close')}</button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showConfirmModal && (
        <div className="teacher-payment-modal">
          <div className="teacher-payment-modal-content">
            <h2>{t('confirm_payment')}</h2>
            <p>{t('teacherName')}: {confirmData.teacherName}</p>
            <p>{t('period')}: {confirmData.period}</p>
            <p>{t('nettotal')}: {confirmData.totalAmount}</p>
            
            <label>
              <input 
                type="checkbox" 
                checked={withholdingTax} 
                onChange={() => setWithholdingTax(!withholdingTax)} 
              /> 
              {t('sumWithholdingTax')}
            </label>
      
            <div className="modal-actions">
            <button onClick={() => setShowConfirmModal(false)}>{t('cancel')}</button>
              <button onClick={confirmApproval}>{t('confirm')}</button>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRoleAccess(TeacherDetailsPage, ['superadmin']);
