import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import FullCalendar from '@fullcalendar/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEnvelope, faPhone, faHome, faGraduationCap, faUser, faPhoneAlt, faSchool, faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import LineGraph from './LineGraph';
import InvoiceModal from '../Accounting/InvoiceModal';
import Modal from '../../components/Modal';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import { useTranslation } from 'react-i18next';
import './Student.css';


const sortByDateAscending = (schedule) => {
  return schedule.sort((a, b) => new Date(a.date) - new Date(b.date));
};

const paginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

const filterFutureClasses = (schedule) => {
  const now = new Date();
  return schedule.filter(cls => new Date(cls.date) >= now);
};

function StudentDashboardAdmin() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [error, setError] = useState(null);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [testScores, setTestScores] = useState([]);
  const [newTestScore, setNewTestScore] = useState({ date: '', test: '', score: '' });
  const [studentInfo, setStudentInfo] = useState([]);
  const [newStudentInfo, setNewStudentInfo] = useState('');
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [popupData, setPopupData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year:'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };
  
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')} ${t('hr')}`;
  };
  const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      const daysOfWeek = t('daysOfWeek', { returnObjects: true });
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return t('invalidDay');
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const studentData = response.data;
        setStudent({
          ...studentData,
          photourl: studentData.photourl || 'https://via.placeholder.com/150',
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
        setError('Failed to fetch student data.');
      }
    };

    const fetchTestScores = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/test-scores/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTestScores(response.data);
      } catch (error) {
        console.error('Error fetching test scores:', error);
      }
    };

    const fetchStudentInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/student-info/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentInfo(response.data);
      } catch (error) {
        console.error('Error fetching student info:', error);
      }
    };

    const fetchAttendanceLogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceLogs(response.data);
      } catch (error) {
        console.error('Error fetching attendance logs:', error);
      }
    };

    const fetchBillingHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const { registrations } = response.data;
        setBillingHistory(registrations);
      } catch (error) {
        console.error('Error fetching billing history:', error);
      }
    };

    fetchStudentData();
    fetchTestScores();
    fetchStudentInfo();
    fetchAttendanceLogs();
    fetchBillingHistory();
  }, [id, API_URL]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTestScore({ ...newTestScore, [name]: value });
  };

  const handleAddTestScore = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/test-scores`, {
        student_id: id,
        test_date: newTestScore.date,
        test_name: newTestScore.test,
        score: newTestScore.score
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTestScores([response.data, ...testScores]);
      setNewTestScore({ date: '', test: '', score: '' });
    } catch (error) {
      console.error('Error adding test score:', error);
    }
  };

  const handleAddStudentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/student-info`, {
        student_id: id,
        info_text: newStudentInfo
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudentInfo([response.data, ...studentInfo]);
      setNewStudentInfo('');
    } catch (error) {
      console.error('Error adding student info:', error);
    }
  };

  const handleDeleteTestScore = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${API_URL}/test-scores/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        setTestScores(testScores.filter(score => score.id !== id));
      }
    } catch (error) {
      console.error('Error deleting test score:', error);
    }
  };

  const handleDeleteStudentInfo = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${API_URL}/student-info/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        setStudentInfo(studentInfo.filter(info => info.id !== id));
      }
    } catch (error) {
      console.error('Error deleting student info:', error);
    }
  };

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);
  
    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;
  
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }
  
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const handleInvoiceClick = (registration) => {
    setSelectedRegistration(registration);
    setShowInvoiceModal(true);
  };

  const closeInvoiceModal = () => {
    setShowInvoiceModal(false);
    setSelectedRegistration(null);
  };

  const openSchedulePopup = (data) => {
    setPopupData(data);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const filteredAndSortedBillingHistory = billingHistory.map(enrollment => ({
    ...enrollment,
    schedule: sortByDateAscending(filterFutureClasses(enrollment.schedule))
  })).filter(enrollment => enrollment.schedule.length > 0);

  const allSchedules = filteredAndSortedBillingHistory.flatMap(enrollment =>
    enrollment.schedule.map(schedule => ({
      ...schedule,
      class_code: enrollment.class_code
    }))
  );

  const paginatedSchedule = paginate(allSchedules, pageSize, currentPage);

  const totalPages = Math.ceil(allSchedules.length / pageSize);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;


  return (
    <div className="student-dashboard-container-admin">
      <Sidebar />
      <Header />
      <div className="student-header">
        <div className="student-header-info">
          <h2>{student.firstname} {student.lastname} ({student.nickname})</h2>
        </div>
      </div>
      <div className="student-dashboard-content">
        <div className="student-profile">
          <h2>{t('profile')}</h2>
          <img src={student.photourl} alt={`${student.firstname} ${student.lastname}`} className="student-image2" />
            <p><FontAwesomeIcon icon={faEnvelope} className="icon" /><strong>{t('email')}:</strong> {student.email}</p>
            <p><FontAwesomeIcon icon={faPhone} className="icon" /><strong>{t('phone')}:</strong> {student.phone}</p>
            <p><FontAwesomeIcon icon={faHome} className="icon" /><strong>{t('address')}:</strong> {student.address}</p>
            <p><FontAwesomeIcon icon={faBirthdayCake} className="icon" /><strong>{t('dob')}:</strong> {formatDate(student.date_of_birth)}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} className="icon" /><strong>{t('currentEducationLevel')}:</strong> {student.currenteducationlevel}</p>
            <p><FontAwesomeIcon icon={faUser} className="icon" /><strong>{t('guardianName')}:</strong> {student.guardianname}</p>
            <p><FontAwesomeIcon icon={faPhoneAlt} className="icon" /><strong>{t('guardianContact')}:</strong> {student.guardiancontact}</p>
            <p><FontAwesomeIcon icon={faSchool} className="icon" /><strong>{t('schoolName')}:</strong> {student.schoolname}</p>
        </div>
        <div className="student-attendance">
          <h2>{t('testScores')}</h2>
          <table>
            <tbody>
              {testScores.map((score, index) => (
                <tr key={index}>
                  <td>{formatDate(score.test_date)}</td>
                  <td>{score.test_name}</td>
                  <td>{score.score}</td>
                  <td><button onClick={() => handleDeleteTestScore(score.id)} className="delete-score"><FontAwesomeIcon icon={faTrash} /></button></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="test-score-form">
            <input
              type="date"
              name="date"
              value={newTestScore.date}
              onChange={handleInputChange}
              placeholder={t('date')}
            />
            <input
              type="text"
              name="test"
              value={newTestScore.test}
              onChange={handleInputChange}
              placeholder={t('test')}
            />
            <input
              type="text"
              name="score"
              value={newTestScore.score}
              onChange={handleInputChange}
              placeholder={t('score')}
            />
            <button onClick={handleAddTestScore}>{t('addTestScore')}</button>
          </div>
        </div>
        <div className="today-classes">
          <h2>{t('studentInfo')}</h2>
          <table>
            <tbody>
              {studentInfo.map((info, index) => (
                <tr key={index}>
                  <td>{formatDate(info.created_at)} - {info.info_text} ({info.created_by_firstname})</td>
                  <td><button onClick={() => handleDeleteStudentInfo(info.id)} className="delete-score"><FontAwesomeIcon icon={faTrash} /></button></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="student-info-form">
            <textarea
              value={newStudentInfo}
              onChange={(e) => setNewStudentInfo(e.target.value)}
              placeholder={t('enterStudentInfo')}
            />
            <button onClick={handleAddStudentInfo} className="add-button">{t('add')}</button>
          </div>
        </div>
      </div>

      <div className="student-list-box-container">
         <div className="student-list-box">
          <h2>{t('classSchedule')}</h2>
          <table className="classtable">
            <thead>
              <tr>
                <th>{t('date')}</th>
                <th>{t('classCode')}</th>
                <th>{t('subject')}</th>
                <th>{t('teacher')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedSchedule.map((cls, idx) => (
                <tr key={idx}>
                  <td>{formatDate(cls.date)}</td>
                  <td>{cls.class_code}</td>
                  <td>{cls.subject}</td>
                  <td>{cls.teacher}</td>
                  <td>{formatTime(cls.time)}</td>
                  <td>{cls.hours}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination-container">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={currentPage === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
        <div className="student-list-box">
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            locale={i18n.language === 'th' ? thLocale : 'en'} // Apply the locale
            events={billingHistory.flatMap((enrollment) =>
              enrollment.schedule.map((cls) => {
                const formattedStartTime = cls.time.slice(0, 5); // Extract the time part
                const startDateTime = `${cls.date.split('T')[0]}T${formattedStartTime}:00`;
                const formattedEndTime = calculateEndTime(formattedStartTime, cls.hours);
                const endDateTime = `${cls.date.split('T')[0]}T${formattedEndTime}:00`;

                return {
                  title: `${enrollment.class_code} - ${enrollment.subject}`,
                  start: startDateTime,
                  end: endDateTime,
                  extendedProps: {
                    teachers: enrollment.teachers.map((teacher) => teacher.teacher_name).join(', '),
                    time: formattedStartTime,
                    hours: cls.hours,
                  }
                };
              })
            )}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek'
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false // Use 24-hour format
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false // Use 24-hour format
            }}
            height="auto"
            slotMinTime="06:00:00"
            slotMaxTime="21:00:00"
          />
        </div>
      </div>

      <div className="all-classes">
        <h2>{t('allCurrentEnrollments')}</h2>
        <table>
          <thead>
            <tr>
              <th>{t('classCode')}</th>
              <th>{t('subjects')}</th>
              <th>{t('schedule')}</th>
              <th>{t('totalHours')}</th>
              <th>{t('access')}</th>
            </tr>
          </thead>
          <tbody>
            {billingHistory.map((enrollment, index) => (
              <tr key={index}>
                <td>{enrollment.class_code}</td>
                <td>
                  {enrollment.subjects.map((subject, idx) => (
                    <div key={idx}>{subject.title}</div>
                  ))}
                </td>

                <td>
                  <button onClick={() => openSchedulePopup(sortByDateAscending(enrollment.schedule))}>{t('viewSchedule')}</button>
                </td>
                <td>{enrollment.total_hours}</td>
                <td>
                  <Link to={`/classdetails/${enrollment.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                    <button>{t('courseDetails')}</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="additional-info">
        <div className="attendance-logs">
          <h2>{t('attendanceLogs')}</h2>
          <table>
            <thead>
              <tr>
                <th>{t('date')}</th>
                <th>{t('course')}</th>
                <th>{t('classId')}</th>
                <th>{t('subject')}</th>
                <th>{t('attendance')}</th>
              </tr>
            </thead>
            <tbody>
              {attendanceLogs.map((log, index) => (
                <tr key={index}>
                  <td>{formatDate(log.date)}</td>
                  <td>{log.class_code}</td>
                  <td>{log.classid}</td>
                  <td>{log.subject_name}</td>
                  <td>{log.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="billing-history">
          <h2>{t('billingHistory')}</h2>
          <table>
            <thead>
              <tr>
                <th>{t('invNo')}</th>
                <th>{t('amount')}</th>
                <th>{t('billingDate')}</th>
                <th>{t('status')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {billingHistory.map((bill, index) => (
                <tr key={index}>
                  <td>
                    <span
                      className="link-button2"
                      onClick={() => handleInvoiceClick(bill)}
                      style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                    >
                      {bill.invoiceno}
                    </span>
                  </td>
                  <td>{bill.final_price}</td>
                  <td>{formatDate(bill.date)}</td>
                  <td>{bill.payment_confirmed ? t('confirmed') : t('pending')}</td>
                  <td>
                    <button>{t('receipt')}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <LineGraph />

      {showInvoiceModal && selectedRegistration && (
        <InvoiceModal
          show={showInvoiceModal}
          registration={selectedRegistration}
          onClose={closeInvoiceModal}
        />
      )}

      {popupData && (
        <Modal isOpen={!!popupData} onClose={closePopup}>
          <h2>{t('scheduleDetails')}</h2>
          <table className="schedule-table">
            <thead>
              <tr>
                <th>{t('subjectName')}</th>
                <th>{t('teacherName')}</th>
                <th>{t('day')}</th>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
              {popupData.map((slot, index) => (
                <tr key={index}>
                  <td>{slot.subject}</td>
                  <td>{slot.teacher}</td>
                  <td>{getDayOfWeek(slot.date)}</td>
                  <td>{formatDate(slot.date)}</td>
                  <td>{formatTime(slot.time)}</td>
                  <td>{slot.hours}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
}

export default withRoleAccess(StudentDashboardAdmin, ['admin', 'superadmin']);
