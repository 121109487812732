import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { sarabunFont } from '../../sarabunFont'; 
import './Registrations.css';

// Function to load the Sarabun font into jsPDF
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};

const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const Registrations = () => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [selectedClass, setSelectedClass] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [promotion, setPromotion] = useState('');
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [memo, setMemo] = useState('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [vat, setVat] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const sigCanvas = useRef(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const studentsData = response.data.students || [];
      setStudents(studentsData);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);
  

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const classesData = Array.isArray(response.data) ? response.data : [];
      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchStudents();
    fetchClasses();
  }, [fetchStudents, fetchClasses]);

  const studentOptions = students.map(student => ({
    value: student.userid,
    label: `${student.firstname} ${student.lastname}`
  }));

  const classOptions = classes.map(cls => ({
    value: cls.groupid,
    label: `${cls.class_code} ${t('start_date')} ${formatDate(cls.start_date)}`
  }));

  const handleStudentSelect = (selectedOption) => {
    setSelectedStudent(selectedOption ? selectedOption.value : '');
    const selectedStudent = students.find(student => student.userid === selectedOption.value);
    setStudentDetails(selectedStudent || {});
  };

  const handleClassSelect = (selectedOption) => {
    setSelectedClass(selectedOption ? selectedOption.value : '');
    const selectedClass = classes.find(cls => cls.groupid === selectedOption.value);
    setSchedule(selectedClass ? selectedClass.classes : []);
    setPrice(selectedClass ? selectedClass.price : 0);
    setPromotion('');
    setTotalPrice(selectedClass ? selectedClass.price : 0);
  };

  const handlePromotionChange = (e) => {
    const promoValue = e.target.value;
    setPromotion(promoValue);

    const parsedPromotion = parseFloat(promoValue);
    let newTotalPrice = price;
    if (!isNaN(parsedPromotion)) {
      newTotalPrice = price - parsedPromotion;
    } else if (promoValue.endsWith('%')) {
      const percentage = parseFloat(promoValue.slice(0, -1));
      if (!isNaN(percentage)) {
        newTotalPrice = price - (price * percentage / 100);
      }
    }
    if (vat) {
      newTotalPrice = newTotalPrice * 1.07;
    }
    setTotalPrice(newTotalPrice);
  };

  const handleVatChange = (e) => {
    const isChecked = e.target.checked;
    setVat(isChecked);
    let newTotalPrice = price;
    if (promotion) {
      const parsedPromotion = parseFloat(promotion);
      if (!isNaN(parsedPromotion)) {
        newTotalPrice = price - parsedPromotion;
      } else if (promotion.endsWith('%')) {
        const percentage = parseFloat(promotion.slice(0, -1));
        if (!isNaN(percentage)) {
          newTotalPrice = price - (price * percentage / 100);
        }
      }
    }
    if (isChecked) {
      newTotalPrice = newTotalPrice * 1.07;
    }
    setTotalPrice(newTotalPrice);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleConfirmPayment = async () => {
    setIsPaymentConfirmed(true);

    const validPromotion = promotion ? parseFloat(promotion) : 0;
    if (isNaN(validPromotion)) {
      alert("Invalid promotion value");
      return;
    }

    const validPrice = price ? parseFloat(price) : 0;
    if (isNaN(validPrice) || validPrice <= 0) {
      alert("Invalid price value");
      return;
    }

    const validTotalPrice = totalPrice ? parseFloat(totalPrice) : 0;
    if (isNaN(validTotalPrice) || validTotalPrice <= 0) {
      alert("Invalid total price value");
      return;
    }

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData ? userData.userId : null;

    const signature = sigCanvas.current.isEmpty() ? null : sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    const registrationData = {
      studentId: selectedStudent,
      classId: selectedClass,
      promotion: validPromotion,
      finalPrice: validTotalPrice,
      paymentMethod: paymentMethod,
      paymentConfirmed: !!signature,
      createdBy: userId,
      price: validPrice,
      discount: validPromotion,
      signature: signature,
      memo: memo
    };

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/registrations`, registrationData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Show confirmation modal
      setConfirmationModalOpen(true);

      setSelectedStudent('');
      setStudentDetails({});
      setSelectedClass('');
      setSchedule([]);
      setPromotion('');
      setPrice(0);
      setTotalPrice(0);
      setPaymentMethod('');
      setMemo('');
      setIsPaymentConfirmed(false);
      setVat(false);
      clearSignature();
      closeModal();
    } catch (error) {
      console.error('Error saving registration:', error);
    }
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const generateSchedulePDF = (schedule) => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
    doc.setFont('Sarabun', 'normal');
  
    doc.setFontSize(18);
    doc.text('Class Schedule', 105, 10, { align: 'center' });
  
    doc.autoTable({
      startY: 20,
      head: [['Date', 'Day', 'Time', 'Hours', 'Subject Name']],
      body: schedule.map(slot => [
        formatDate(slot.date),
        slot.schedule_day,
        formatTime(slot.schedule_time),
        slot.schedule_hour,
        slot.subject_name,
      ]),
      styles: { font: 'Sarabun', fontStyle: 'normal' }
    });
  
    doc.save('class_schedule.pdf');
  };

  const generateQuotationPDF = () => {
  const doc = new jsPDF();
  loadSarabunFont(doc);
  doc.setFont('Sarabun', 'normal');

  // School details
  const schoolLogo = 'https://png.pngtree.com/png-clipart/20230407/original/pngtree-education-and-school-logo-design-template-png-image_9035365.png';
  const schoolName = 'HELLO TUTOR';
  const schoolAddress = '123 Main St.';
  const schoolPhone = '0987654321';
  const today = moment().format('DD MMM YYYY');
  const expirationDate = moment().add(2, 'days').format('DD MMM YYYY');
  const qrCodeImage = 'https://pbs.twimg.com/media/DY8Qm7_U0AAzaFm.jpg';

  // Load the school logo image and draw it
  doc.addImage(schoolLogo, 'PNG', 10, 10, 50, 0); // Set height to 0 to maintain aspect ratio
  doc.setFontSize(18);
  doc.text(schoolName, 70, 20);
  doc.setFontSize(12);
  doc.text(`${t('registration.address')}: ${schoolAddress}`, 70, 30);
    doc.text(`${t('registration.phone')}: ${schoolPhone}`, 70, 35);

    // Add date and expiration information
    const startY = 75; // Start below the logo
    doc.text(`${t('registration.date_of_quotation')}: ${today}`, 10, startY);
    doc.text(`${t('registration.quotation_expiration')}: ${expirationDate}`, 10, startY + 5);

    // Student and class details
    doc.text(`${t('registration.student_name')}: ${studentDetails.firstname} ${studentDetails.lastname}`, 10, startY + 15);
    doc.text(`${t('registration.address')}: ${studentDetails.address || 'N/A'}`, 10, startY + 20);
    doc.text(`${t('registration.phone')}: ${studentDetails.phone || 'N/A'}`, 10, startY + 25);
    
    doc.text(`${t('registration.class_code')}: ${classes.find(cls => cls.groupid === selectedClass)?.class_code || 'N/A'}`, 10, startY + 30);

  // Table with pricing details
  doc.autoTable({
    startY: startY + 40,
    head: [[t('Details'), t('Value')]],
    body: [
      ['Price', formatNumber(price)],
      ['Promotion', promotion],
      ['VAT', vat ? formatNumber(price * 0.07) : 'N/A'],
      ['Total Price', formatNumber(totalPrice)],
      ['Payment Method', paymentMethod],
      ['Memo', memo],
    ],
    styles: { font: 'Sarabun', fontStyle: 'normal' }
  });

  // Add QR code image for payment
  doc.addImage(qrCodeImage, 'JPEG', 10, doc.lastAutoTable.finalY + 10, 50, 0); // Set height to 0 to maintain aspect ratio

  // Save PDF
  doc.save('quotation.pdf');
};
  return (
    <div className="registration-container">
      <Sidebar />
      <Header />
      <h1>{t('registration.register_for_course')}</h1>
      <form className="registration-form">
        <div className="form-left">
          <div className="re-form-group">
            <label htmlFor="student">{t('registration.select_student')}</label>
            <Select
              options={studentOptions}
              onChange={handleStudentSelect}
              placeholder={t('registration.search_and_select_student')}
            />
          </div>
          {selectedStudent && (
            <div className="re-form-address">
              <p><strong>{t('registration.address')}:</strong> {studentDetails.address || 'N/A'}</p>
              <p><strong>{t('registration.phone')}:</strong> {studentDetails.phone || 'N/A'}</p>
            </div>
          )}
          <div className="re-form-group">
            <label htmlFor="class">{t('registration.select_class')}</label>
            <Select
              options={classOptions}
              onChange={handleClassSelect}
              placeholder={t('registration.select_class')}
            />
          </div>

          {selectedClass && (
            <div className="re-form-group">
              <label>{t('registration.class_schedule')}</label>
              <div className="schedule-list">
              {schedule.map((slot, index) => (
                <div key={index} className="schedule-item">
                  <span>{formatDate(slot.date)} {slot.schedule_day}: </span>
                  <span>{formatTime(slot.schedule_time)} ({slot.schedule_hour}) - </span>
                  <span>{slot.subject_name}</span>
                </div>
              ))}
            </div>
            </div>
          )}
          <button type="button" className="submit-button" onClick={() => generateSchedulePDF(schedule)}>
            <FontAwesomeIcon icon={faPrint} /> {t('registration.print_schedule')}
          </button>
        </div>
        <div className="form-right">
          <div className="re-form-group">
            <label>{t('registration.price')}</label>
            <p className="price-display">{formatNumber(price)}</p>
          </div>
          <div className="re-form-group">
            <label htmlFor="promotion">{t('registration.promotion_discount')}</label>
            <input
              type="text"
              id="promotion"
              value={promotion}
              onChange={handlePromotionChange}
              placeholder={t('registration.enter_promotion_value')}
            />
          </div>
          <div className="custom-checkbox">
            <input type="checkbox" id="vat" checked={vat} onChange={handleVatChange} />
            <label htmlFor="vat" className="checkbox-label">{t('registration.vat')}</label>
            {vat && <span className="vat-amount">{formatNumber(price * 0.07)}</span>}
          </div>
          <div className="re-form-group">
            <label>{t('registration.total_price')}</label>
            <p className="price-display">{formatNumber(totalPrice)}</p>
          </div>

          <button type="button" className="quotation-button" onClick={generateQuotationPDF}>
            <FontAwesomeIcon icon={faFileInvoice} /> {t('registration.quotation')}
          </button>
          <div className="re-form-group">
            <label htmlFor="paymentMethod">{t('registration.select_payment_method')}</label>
            <select id="paymentMethod" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
              <option value="">{t('registration.select_payment_method')}</option>
              <option value="cash">{t('registration.cash')}</option>
              <option value="transfer">{t('registration.transfer')}</option>
              <option value="qr">{t('registration.qr_code')}</option>
              <option value="credit">{t('registration.credit_card')}</option>
            </select>
          </div>

          <div className="re-form-group">
            <label htmlFor="memo">{t('registration.memo')}</label>
            <textarea
              id="memo"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              placeholder={t('registration.enter_additional_notes')}
            />
          </div>
          <div className="re-form-group">
            <label className="checkbox-label">{t('registration.confirm_payment')}</label>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            />
          </div>
          <div className="re-form-group">
            <button type="button" onClick={clearSignature} className="singature-button">{t('registration.clear_signature')}</button>
          </div>

          <button type="button" className="submit-button" onClick={openModal}>
            <FontAwesomeIcon icon={faFileInvoice} /> {t('registration.confirm_registration')}
          </button>
        </div>
      </form>
      {isPaymentConfirmed && <p>{t('registration.payment_confirmed')}</p>}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2>{t('registration.confirm_registration_details')}</h2>
        <div>
          <p><strong>{t('registration.student')}:</strong> {studentDetails.firstname} {studentDetails.lastname}</p>
          <p><strong>{t('registration.address')}:</strong> {studentDetails.address || 'N/A'}</p>
          <p><strong>{t('registration.phone')}:</strong> {studentDetails.phone || 'N/A'}</p>
          <p><strong>{t('registration.class')}:</strong> {classes.find(cls => cls.groupid === selectedClass) ? `${classes.find(cls => cls.groupid === selectedClass).class_code} - ${classes.find(cls => cls.groupid === selectedClass).subject_name}` : selectedClass}</p>
          <p><strong>{t('registration.price')}:</strong> {formatNumber(price)}</p>
          <p><strong>{t('registration.promotion')}:</strong> {promotion}</p>
          <p><strong>{t('registration.vat')}:</strong> {vat ? formatNumber(price * 0.07) : 'N/A'}</p>
          <p><strong>{t('registration.total_price')}:</strong> {formatNumber(totalPrice)}</p>
          <p><strong>{t('registration.payment_method')}:</strong> {paymentMethod}</p>
          <p><strong>{t('registration.memo')}:</strong> {memo}</p>
          {sigCanvas.current && !sigCanvas.current.isEmpty() && <img src={sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')} alt="signature" style={{ width: '100%', maxHeight: '200px' }} />}
        </div>
        <button type="button" onClick={handleConfirmPayment}>{t('registration.confirm')}</button>
      </Modal>
      <Modal isOpen={confirmationModalOpen} onClose={closeConfirmationModal}>
        <h2>{t('registration.successful_registration')}</h2>
      </Modal>
    </div>
  );
}

export default withRoleAccess(Registrations, ['superadmin', 'admin']);
