import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  PieChart, Pie, Cell, BarChart, Bar, ResponsiveContainer
} from 'recharts';
import './Reports.css';

const mockData = {
  revenue: {
    ytd: 120000,
    mtd: 15000,
    today: 500
  },
  students: {
    newStudentsYTD: 150,
    newStudentsMTD: 20,
    newRegistrationsYTD: 200,
    newRegistrationsMTD: 25,
    avgStudentsPerClass: 12,
    performance: [
      { name: 'John Doe', score: 95 },
      { name: 'Jane Smith', score: 88 },
      { name: 'Michael Brown', score: 76 }
    ]
  },
  teachers: {
    total: 20,
    byDepartment: [
      { department: 'Math', count: 5 },
      { department: 'Science', count: 8 },
      { department: 'Languages', count: 7 }
    ],
    fullTime: 12,
    partTime: 8,
    mvpTeacher: 'Jane Smith'
  },
  classes: {
    totalHoursThisMonth: 120,
    revenuePerHour: 125,
    utilizationRate: 85, // utilization rate in percentage
  },
  schools: {
    topSchools: [
      { name: 'School A', students: 50 },
      { name: 'School B', students: 40 },
      { name: 'School C', students: 30 },
      { name: 'School D', students: 20 },
      { name: 'School E', students: 10 }
    ]
  },
  teacherPerformance: [
    { name: 'Jane Smith', averageRating: 4.9, totalClasses: 50 },
    { name: 'John Doe', averageRating: 4.7, totalClasses: 45 },
    { name: 'Michael Brown', averageRating: 4.6, totalClasses: 40 }
  ],
  monthlyRevenue: [
    { month: 'Jan', revenue: 10000 },
    { month: 'Feb', revenue: 12000 },
    { month: 'Mar', revenue: 15000 },
    { month: 'Apr', revenue: 8000 },
    { month: 'May', revenue: 9000 },
    { month: 'Jun', revenue: 11000 },
    { month: 'Jul', revenue: 13000 },
    { month: 'Aug', revenue: 12000 },
    { month: 'Sep', revenue: 14000 },
    { month: 'Oct', revenue: 15000 },
    { month: 'Nov', revenue: 16000 },
    { month: 'Dec', revenue: 17000 },
  ]
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Reports = () => {
  const [data] = useState(mockData);

  useEffect(() => {
    // Fetch data from the server
    // const fetchData = async () => {
    //   try {
    //     const token = localStorage.getItem('token'); // Get the token from local storage
    //     const response = await axios.get('http://localhost:3001/api/reports', {
    //       headers: {
    //         Authorization: `Bearer ${token}` // Include the token in the request headers
    //       }
    //     });
    //     setData(response.data);
    //   } catch (error) {
    //     console.error('Error fetching reports data:', error);
    //   }
    // };

    // fetchData();
  }, []);

  return (
    <div className="reports-container">
      <Sidebar />
      <Header />

      <div className="report-grid">
        <div className="report-box">
          <h3>Revenue Report</h3>
          <div className="report-item">
            <span>YTD Revenue:</span>
            <strong>${data.revenue.ytd.toLocaleString()}</strong>
          </div>
          <div className="report-item">
            <span>MTD Revenue:</span>
            <strong>${data.revenue.mtd.toLocaleString()}</strong>
          </div>
          <div className="report-item">
            <span>Today's Revenue:</span>
            <strong>${data.revenue.today.toLocaleString()}</strong>
          </div>
          <div className="report-item">
            <span>Total Class Hour:</span>
            <strong>{data.classes.totalHoursThisMonth} hours</strong>
          </div>
          <div className="report-item">
            <span>Revenue per Hour:</span>
            <strong>${data.classes.revenuePerHour.toLocaleString()}</strong>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data.monthlyRevenue} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="report-box">
          <h3>Student Report</h3>
          <div className="report-item">
            <span>New Students YTD:</span>
            <strong>{data.students.newStudentsYTD}</strong>
          </div>
          <div className="report-item">
            <span>New Students MTD:</span>
            <strong>{data.students.newStudentsMTD}</strong>
          </div>
          <div className="report-item">
            <span>New Registrations YTD:</span>
            <strong>{data.students.newRegistrationsYTD}</strong>
          </div>
          <div className="report-item">
            <span>New Registrations MTD:</span>
            <strong>{data.students.newRegistrationsMTD}</strong>
          </div>
          <div className="report-item">
            <span>Average Students per Class:</span>
            <strong>{data.students.avgStudentsPerClass}</strong>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data.students.performance} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="score" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="report-box">
          <h3>Student Performance</h3>
          <table className="performance-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {data.students.performance.map((student, index) => (
                <tr key={index}>
                  <td>{student.name}</td>
                  <td>{student.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="report-box">
          <h3>Teacher Report</h3>
          <div className="report-item">
            <span>Total Teachers:</span>
            <strong>{data.teachers.total}</strong>
          </div>
          <div className="report-item">
            <span>Full-Time Teachers:</span>
            <strong>{data.teachers.fullTime}</strong>
          </div>
          <div className="report-item">
            <span>Part-Time Teachers:</span>
            <strong>{data.teachers.partTime}</strong>
          </div>
          <div className="report-item">
            <span>MVP Teacher:</span>
            <strong>{data.teachers.mvpTeacher}</strong>
          </div>
        </div>

        <div className="report-box">
          <h3>Teachers by Department</h3>
          <table className="department-table">
            <thead>
              <tr>
                <th>Department</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {data.teachers.byDepartment.map((dept, index) => (
                <tr key={index}>
                  <td>{dept.department}</td>
                  <td>{dept.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="report-box">
          <h3>Top 5 Schools</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={data.schools.topSchools} dataKey="students" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
                {data.schools.topSchools.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="report-box">
          <h3>Class Utilization</h3>
          <div className="report-item">
            <span>Utilization Rate:</span>
            <strong className="utilization-rate">{data.classes.utilizationRate}%</strong>
          </div>
        </div>

        <div className="report-box">
          <h3>Teacher Performance</h3>
          <table className="performance-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Average Rating</th>
                <th>Total Classes</th>
              </tr>
            </thead>
            <tbody>
              {data.teacherPerformance.map((teacher, index) => (
                <tr key={index}>
                  <td>{teacher.name}</td>
                  <td>{teacher.averageRating}</td>
                  <td>{teacher.totalClasses}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Reports;
