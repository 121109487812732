import React, { useRef } from 'react';
import './PayStubModal.css';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

const formatNumber = (number, locale) => {
  if (isNaN(number)) {
    return '0.00';
  }
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const formatDate = (dateString, locale) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString(locale, options).toUpperCase();
};

const PayStubModal = ({ show, onClose, payStubData }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef(); // Ref for the modal content

  const handlePrint = useReactToPrint({
    content: () => componentRef.current, // Reference to the modal content
  });

  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Close the modal if the click is outside the modal content
    }
  };

  const calculateSubtotal = (rate, hours) => rate * hours;
  const calculateWithholdingTax = (subtotal) => subtotal * 0.03;
  const calculateTotal = (subtotal, withholdingTax) => subtotal - withholdingTax;

  const calculatedClasses = payStubData.classes.map(cls => {
    const subtotal = calculateSubtotal(cls.rate, cls.hours);
    const withholdingTax = calculateWithholdingTax(subtotal);
    const total = calculateTotal(subtotal, withholdingTax);

    return {
      ...cls,
      subtotal,
      withholdingTax,
      total
    };
  });

  const totalSubtotal = calculatedClasses.reduce((acc, cls) => acc + cls.subtotal, 0);
  const totalWithholdingTax = calculatedClasses.reduce((acc, cls) => acc + cls.withholdingTax, 0);
  const totalPay = calculatedClasses.reduce((acc, cls) => acc + cls.total, 0);

  return (
    <div className="paystub-modal-overlay" onClick={handleOverlayClick}>
      <div className="paystub-modal" ref={componentRef}>
        <span className="modal-close-button" onClick={onClose}>&times;</span>
        <h2>{t('Earnings Statement')}</h2>
        <div className="paystub-details" id="paystub-content">
          <div className="paystub-header">
            <div className="company-info">
              <p><strong>{payStubData.companyName}</strong></p>
              <p>{payStubData.companyAddress}</p>
              <p>{payStubData.companyPhone}</p>
              <p>{t('Tax ID')}: {payStubData.companyTaxId}</p>
            </div>
            <div className="paystub-info">
              <h3>{t('paystub')}</h3>
              <p>{t('Pay Period')}: {payStubData.payPeriod}</p>
              <p>{t('Pay Date')}: {formatDate(payStubData.payDate, i18n.language)}</p>
            </div>
          </div>
          <div className="employee-info">
            <h3>{t('Employee Info')}</h3>
            <p>{t('name')}: {payStubData.teacherName}</p>
            <p>{t('address')}: {payStubData.teacherAddress}</p>
            <p>{t('phone')}: {payStubData.teacherPhone}</p>
            <p>{t('nationalID')}: {payStubData.teacherNationalId}</p>
          </div>
          <div className="paystub-table">
            <table>
              <thead>
                <tr>
                  <th>{t('class')}</th>
                  <th>{t('hourly_rate')}</th>
                  <th>{t('hours')}</th>
                  <th>{t('subtotal')}</th>
                  <th>{t('sumWithholdingTax')}</th>
                  <th>{t('nettotal')}</th>
                </tr>
              </thead>
              <tbody>
                {calculatedClasses.map(cls => (
                  <tr key={cls.classId}>
                    <td>{cls.classId}</td>
                    <td>{formatNumber(cls.rate, i18n.language)}</td>
                    <td>{cls.hours}</td>
                    <td>{formatNumber(cls.subtotal, i18n.language)}</td>
                    <td>{formatNumber(cls.withholdingTax, i18n.language)}</td>
                    <td>{formatNumber(cls.total, i18n.language)}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>{t('Total')}</strong></td>
                  <td></td>
                  <td>{payStubData.totalHours}</td>
                  <td>{formatNumber(totalSubtotal, i18n.language)}</td>
                  <td>{formatNumber(totalWithholdingTax, i18n.language)}</td>
                  <td><strong>{formatNumber(totalPay, i18n.language)}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="taxes-info">
            <h4>{t('Taxes / Deductions')}</h4>
            <p>{t('Withholding Tax')}: {formatNumber(totalWithholdingTax, i18n.language)}</p>
          </div>
        </div>
        <div className="paystub-actions">
          <button onClick={handlePrint}>{t('Print Pay Stub')}</button>
        </div>
      </div>
    </div>
  );
};

export default PayStubModal;
