import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import './Classes.css';

const CurrentClasses = () => {
  const { t, i18n } = useTranslation();
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 30;
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return 'Invalid Date';
    }
  };

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    } catch (error) {
      console.error('Invalid time value:', timeString);
      return 'Invalid Time';
    }
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    try {
      const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value, 10));
      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hr')}`;
    } catch (error) {
      console.error('Invalid schedule hour value:', scheduleHourString);
      return 'Invalid Time';
    }
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = t('daysOfWeek', { returnObjects: true });
    return daysOfWeek[date.getDay()];
  };

  const fetchClassGroups = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClassGroups(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching class groups:', error);
      setError(t('fetchClassGroupsError'));
      setLoading(false);
    }
  }, [API_URL, t]);

  const fetchRegistrationsCount = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setRegistrationsCount(response.data);
    } catch (error) {
      console.error('Error fetching registrations count:', error);
      setError(t('fetchRegistrationsCountError'));
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchClassGroups();
    fetchRegistrationsCount();
  }, [fetchClassGroups, fetchRegistrationsCount]);

  const handleScheduleClick = (schedule) => {
    const sortedSchedule = [...schedule].sort((a, b) => new Date(b.date) - new Date(a.date));
    setPopupData(sortedSchedule);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  // Get current date
  const currentDate = new Date();

  // Sort and filter classes: sort by start date and filter out past classes
  const sortedClassGroups = classGroups
    .filter(cls => new Date(cls.end_date) >= currentDate) // Filter out classes with past end dates
    .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

  // Filter classes based on search term
  const filteredClassGroups = sortedClassGroups.filter(cls =>
    cls.class_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredClassGroups.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClassGroups = filteredClassGroups.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPagination = () => {
    const pages = [];

    pages.push(
      <button
        key="prev"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="page-button"
      >
        {t('prev')}
      </button>
    );

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`page-button ${i === currentPage ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    pages.push(
      <button
        key="next"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="page-button"
      >
        {t('next')}
      </button>
    );

    return pages;
  };

  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find(rc => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="cc-class-container">
        <div className="cc-class-header">
          <h1>{t('ourCourses')}</h1>        
          
          </div>
          <div className="cc-search-container">
            <input
              type="text"
              placeholder={t('searchByClassCode')}
              value={searchTerm}
              onChange={handleSearchChange}
              className="cc-search-input"
            />
          </div>

          <div className="pagination-container">
            {renderPagination()}
          </div>

        <div className="cc-list">
          {currentClassGroups.map((cls, index) => (
            <div key={index} className="cc-item">
              <div className={`cc-icon cc-icon-${index % 3}`}>
                <span>{cls.class_code.charAt(0)}</span>
              </div>
              <div className="cc-content">
                <h3 className="cc-title">
                  {cls.class_code}
                </h3>
                <p className="cc-subtitle">{formatDate(cls.start_date)} - {formatDate(cls.end_date)}</p>
                <p>{t('totalHours')} : {formatMinutesToHours(cls.total_hours)}</p>
                <p>{t('maxRegister')} : {cls.register_capacity}</p>
                <p>{t('totalRegister')} : {getRegisteredStudents(cls.groupid)}</p>
                <p>{t('type')} : {cls.isprivate ? t('private') : t('group')}</p>
                <button onClick={() => handleScheduleClick(cls.classes)} className="cc-schedule-button">
                  {t('viewSchedule')}
                </button>
              </div>
            </div>
          ))}
        </div>

        <Modal isOpen={!!popupData} onClose={closePopup}>
          <h2>{t('scheduleDetails')}</h2>
          <table className="cc-schedule-table">
            <thead>
              <tr>
                <th>{t('subjectName')}</th>
                <th>{t('teacherName')}</th>
                <th>{t('day')}</th>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
              {popupData && popupData
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((slot, index) => (
                  <tr key={index}>
                    <td>{slot.subject_name}</td>
                    <td>{slot.teacher_name}</td>
                    <td>{getDayOfWeek(slot.date)}</td>
                    <td>{formatDate(slot.date)}</td>
                    <td>{formatTime(slot.schedule_time)}</td>
                    <td>{formatScheduleHour(slot.schedule_hour)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Modal>
      </div>
    </div>
  );
};
export default CurrentClasses;
