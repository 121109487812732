import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditTeacherModal = ({ isOpen, onClose, onSave, teacherId }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        address: '',
        email: '',
        nickname: '',
        photourl: '',
        date_of_birth: '',
        nationalid: '',
        bachelor_degree: '',
        specialize_subjects: '',
        schedule: '',
        isparttime: false,
        yearsexperience: 0,
        bachelor_school: '',
        master_degree: '',
        master_school: '',
        doctoral_degree: '',
        doctoral_school: '',
        hoursrate: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchTeacherData = async () => {
            if (!teacherId) return;
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching teacher data:', error);
                setError(t('fetchTeacherError'));
            } finally {
                setLoading(false);
            }
        };

        if (isOpen) {
            fetchTeacherData();
        }
    }, [teacherId, isOpen, API_URL, t]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    if (!isOpen) return null;

    return (
        <div className="panel-overlay">
            <div className="modal-content">
                <span className="modal-close-icon" onClick={onClose}>&times;</span>
                <div className="modal-header">
                    <h2>{t('editTeacher')}</h2>
                </div>
                {loading ? (
                    <p>{t('loading')}</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('firstName')}:</label>
                                <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('lastName')}:</label>
                                <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('email')}:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('nickname')}:</label>
                                <input type="text" name="nickname" value={formData.nickname} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('dateOfBirth')}:</label>
                                <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('nationalID')}:</label>
                                <input type="text" name="nationalid" value={formData.nationalid} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('phone')}:</label>
                                <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('address')}:</label>
                                <input type="text" name="address" value={formData.address} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('photoURL')}:</label>
                                <input type="text" name="photourl" value={formData.photourl} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('hoursRate')}:</label>
                                <input type="number" name="hoursrate" value={formData.hoursrate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('specializeSubjects')}:</label>
                                <input type="text" name="specialize_subjects" value={formData.specialize_subjects} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('about')}:</label>
                                <input type="text" name="schedule" value={formData.schedule} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('bachelorDegree')}:</label>
                                <input type="text" name="bachelor_degree" value={formData.bachelor_degree} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('bachelorSchool')}:</label>
                                <input type="text" name="bachelor_school" value={formData.bachelor_school} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('masterDegree')}:</label>
                                <input type="text" name="master_degree" value={formData.master_degree} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('masterSchool')}:</label>
                                <input type="text" name="master_school" value={formData.master_school} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('doctoralDegree')}:</label>
                                <input type="text" name="doctoral_degree" value={formData.doctoral_degree} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('doctoralSchool')}:</label>
                                <input type="text" name="doctoral_school" value={formData.doctoral_school} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label className="checkbox-label">
                                    <input 
                                        type="checkbox" 
                                        name="isparttime" 
                                        checked={formData.isparttime} 
                                        onChange={handleChange} 
                                        className="checkbox-input"
                                    />
                                    <span className="checkmark"></span>
                                    {t('partTime')}
                                </label>
                            </div>
                            <div className="form-group">
                                <label>{t('yearsOfExperience')}:</label>
                                <input type="number" name="yearsexperience" value={formData.yearsexperience} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="modal-actions">
                            <button type="button" className="cancel-button" onClick={onClose}>{t('cancel')}</button>
                            <button type="submit" className="submit-button">{t('save')}</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default withRoleAccess(EditTeacherModal, ['admin', 'superadmin']);
