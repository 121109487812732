import React, { useState } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import ChatList from './ChatList'; // Import the ChatList component
import ChatWindow from './ChatWindow'; // If you have separated ChatWindow as well
import './Chat.css';

const ChatPage = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const currentUserId = 1; // Replace with the actual logged-in user ID

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="chat-page">
        <ChatList onSelectUser={setSelectedUser} currentUserId={currentUserId} />
        {selectedUser ? (
          <ChatWindow currentUserId={currentUserId} selectedUserId={selectedUser} />
        ) : (
          <div className="chat-placeholder">Select a user to start chatting</div>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
