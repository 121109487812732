import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../components/common.css'; // Import the CSS file

const ChangePasswordPage = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      await axios.put(`${API_URL}/users/${userId}/change-password`, {
        currentPassword,
        newPassword,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Password changed successfully');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error changing password:', error);
      setError('Failed to change password');
    }
  };

  return (
    <div className="auth-container">
      <div className="form-container2">
      <div className="logo">
          <center><a href="https://plann.tech" target="_blank" rel="noopener noreferrer">
            <img src="https://plann.tech/images/logo-white.png" alt="Logo" width="250px" />
          </a></center>
        </div>
        <h2>Change Password</h2>
        <div className="form-group">
        <input
          type="password"
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          className="input"
        /></div>
        <div className="form-group">
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="input"
        /></div>
        <div className="form-group">
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          className="input"
        /></div>
        {error && <p className="error-message">{error}</p>}
        <button onClick={handleChangePassword} className="button">Change Password</button>
      </div>
      
    </div>
  );
};

export default ChangePasswordPage;

