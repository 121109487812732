import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Classroom.css';
import { useTranslation } from 'react-i18next';

function Classroom() {
  const { t, i18n } = useTranslation();
  const [classrooms, setClassrooms] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('this');
  const [selectedDate, setSelectedDate] = useState(new Date()); // Today's date
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classrooms`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setClassrooms(response.data);
      } catch (error) {
        console.error('Error fetching classrooms:', error);
        setError(t('classroom.fetch_error'));
      } finally {
        setLoading(false);
      }
    };

    const fetchClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classes`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setClasses(response.data);
      } catch (error) {
        console.error('Error fetching classes:', error);
        setError(t('classroom.fetch_error'));
      }
    };

    fetchClassrooms();
    fetchClasses();
  }, [API_URL, t]);

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{error}</p>;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const getMonday = (date) => {
    const day = date.getDay();
    const diff = (day === 0 ? -6 : 1) - day; // adjust when day is Sunday
    const monday = new Date(date);
    monday.setDate(date.getDate() + diff);
    return monday;
  };

  const getClassEventsForWeek = (startDate) => {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    const endDateString = endDate.toISOString().split('T')[0];
    const startDateString = startDate.toISOString().split('T')[0];
    return classes.filter(cls => cls.date >= startDateString && cls.date <= endDateString);
  };

  const renderWeekView = () => {
    const startDate = getMonday(new Date(selectedDate));
    const classEvents = getClassEventsForWeek(startDate);
    const days = [
      t('days.monday'),
      t('days.tuesday'),
      t('days.wednesday'),
      t('days.thursday'),
      t('days.friday'),
      t('days.saturday'),
      t('days.sunday')
    ];
    const weekDates = days.map((day, index) => {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + index);
      return formatDate(date.toISOString().split('T')[0]);
    });

    return (
      <div className="classroom-cards-container">
        {days.map((day, index) => (
          <div key={day} className="day-card">
            <h2>{day} ({weekDates[index]})</h2>
            {classrooms.map(classroom => {
              const events = classEvents.filter(cls => {
                const eventDate = new Date(cls.date);
                return eventDate.getDay() === ((index + 1) % 7) && cls.classroom_number === classroom.number;
              }).sort((a, b) => a.schedule_time.localeCompare(b.schedule_time));

              return (
                <div key={classroom.classroomid} className="classroom-card">
                  <h3>{t('classroom.room')} {classroom.number}</h3>
                  {events.length > 0 ? (
                    <ul className="classroom-events-list">
                      {events.map(event => (
                        <li key={event.classid} className="classroom-event">
                          {`${event.subject_name} (${event.schedule_time.slice(0, 5)}-${event.end_time.slice(0, 5)}) - ${event.teacher_name}`}
                        </li>
                      ))}
                    </ul>
                  ) : <p className="available">{t('classroom.available')}</p>}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  };

  const handlePreviousWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 7);
    setSelectedDate(newDate);
    setActiveTab('previous');
  };

  const handleNextWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 7);
    setSelectedDate(newDate);
    setActiveTab('next');
  };

  const handleThisWeek = () => {
    const thisMonday = getMonday(new Date());
    setSelectedDate(thisMonday);
    setActiveTab('this');
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="classroom-page-container">
      <div className="classroom-view-tabs">
        <button onClick={handlePreviousWeek} className={`${activeTab === 'previous' ? 'active' : ''}`}>
          {t('navigation.previous_week')}
        </button>
        <button onClick={handleThisWeek} className={`${activeTab === 'this' ? 'active' : ''}`}>
          {t('navigation.this_week')}
        </button>
        <button onClick={handleNextWeek} className={`${activeTab === 'next' ? 'active' : ''}`}>
          {t('navigation.next_week')}
        </button>
      </div>
        {renderWeekView()}
      </div>
    </div>
  );
}

export default Classroom;
