import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import getUserIdFromToken from '../../Utils/authUtils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Teacher.css';
import { useTranslation } from 'react-i18next';

const generateMonthYearOptions = () => {
  const options = [];
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = currentYear; year >= currentYear - 5; year--) {
    for (let month = 11; month >= 0; month--) {
      if (year === currentYear && month > currentMonth) continue; // Skip future months
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric'
      }).toUpperCase();
      options.push({ value: `${year}-${month + 1}`, label: monthYear });
    }
  }

  return options;
};

const TeacherHours = () => {
  const userId = getUserIdFromToken();
  const { t, i18n } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [activeTab, setActiveTab] = useState('hours');
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const API_URL = process.env.REACT_APP_API_URL;

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric'};
    return date.toLocaleDateString(i18n.language, options);
  };

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchClassesWithStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        const responseClasses = await axios.get(`${API_URL}/classes/teacher/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const responseStatus = await axios.get(`${API_URL}/classes/${userId}/classes-status`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const pastClasses = responseClasses.data.filter(cls => new Date(cls.date) < new Date());

        const classesWithStatus = pastClasses.map(cls => {
          const statusClass = responseStatus.data.find(statusCls => statusCls.classid === cls.classid);
          return {
            ...cls,
            status: statusClass ? statusClass.status : 'pending',
            payout: statusClass ? statusClass.payout : 'pending'
          };
        });

        setClasses(classesWithStatus);
        setFilteredClasses(classesWithStatus);
      } catch (error) {
        console.error(t('errorFetchingClasses'), error);
      }
    };

    fetchClassesWithStatus();
  }, [userId, API_URL, t]);

  useEffect(() => {
    const applyFilters = () => {
      const filtered = classes.filter(cls => {
        const classDate = new Date(cls.date);
        const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
        const isMonthYearMatch = selectedMonthYear ? classMonthYear === selectedMonthYear : true;
        const isPayoutMatch = payoutFilter ? cls.payout === payoutFilter : true;
        return isMonthYearMatch && isPayoutMatch;
      });

      setFilteredClasses(filtered);
      setCurrentPage(1);
    };

    applyFilters();
  }, [selectedMonthYear, payoutFilter, classes]);

  const handleApprove = (classId) => {
    confirmAlert({
      title: t('Confirm Approval'),
      message: t('Are you sure you want to approve this class?'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => handleSubmitApproval(classId)
        },
        {
          label: t('No'),
          onClick: () => {}
        }
      ]
    });
  };

  const handleSubmitApproval = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/classes/${classId}/approve`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      confirmAlert({
        title: t('Approval Successful'),
        message: t('Class approved successfully!'),
        buttons: [
          {
            label: t('OK'),
            onClick: () => {}
          }
        ]
      });

      setClasses(prevClasses =>
        prevClasses.map(cls =>
          cls.classid === classId ? { ...cls, status: 'approved' } : cls
        )
      );
    } catch (error) {
      confirmAlert({
        title: t('Approval Failed'),
        message: t('Failed to approve class. Please try again.'),
        buttons: [
          {
            label: t('OK'),
            onClick: () => {}
          }
        ]
      });
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(filteredClasses.length / itemsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClasses = filteredClasses.slice(startIndex, endIndex);

  const renderTabs = () => (
    <div className="tutor-tabs-container">
      <button
        className={`tutor-tab-button ${activeTab === 'hours' ? 'active' : ''}`}
        onClick={() => setActiveTab('hours')}
      >
        {t('teacherHours')}
      </button>
      <button
        className={`tutor-tab-button ${activeTab === 'payment' ? 'active' : ''}`}
        onClick={() => setActiveTab('payment')}
      >
        {t('paymentReport')}
      </button>
    </div>
  );

  const monthYearOptions = generateMonthYearOptions();

  return (
    <div>
      <Sidebar />
      <Header />
      {renderTabs()}
      <div className="teacher-hours-container">
        {activeTab === 'hours' && (
          <div className="teacher-hours-content">
            <h1>{t('teacherHours')}</h1>

            {/* Filters */}
            <div className="filter-container">
              <select
                value={selectedMonthYear}
                onChange={(e) => setSelectedMonthYear(e.target.value)}
                className="filter-select"
              >
                <option value="">{t('All Months/Years')}</option>
                {monthYearOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <select
                value={payoutFilter}
                onChange={(e) => setPayoutFilter(e.target.value)}
                className="filter-select"
              >
                <option value="">{t('All Payouts')}</option>
                <option value="pending">{t('Pending')}</option>
                <option value="paid">{t('Paid')}</option>
              </select>
            </div>

            <table>
              <thead>
                <tr>
                  <th>{t('classcode')}</th>
                  <th>{t('classid')}</th>
                  <th>{t('subjectName')}</th>
                  <th>{t('date')}</th>
                  <th>{t('time')}</th>
                  <th>{t('scheduleHour')}</th>
                  <th>{t('approve')}</th>
                  <th>{t('payment')}</th>
                </tr>
              </thead>
              <tbody>
                {currentClasses.map(cls => (
                  <tr key={cls.classid}>
                    <td>{cls.class_code}</td>
                    <td>{cls.classid}</td>
                    <td>{cls.subject_name}</td>
                    <td>{formatDate(cls.date)}</td>
                    <td>{formatTime(cls.schedule_time)}</td>
                    <td>{formatScheduleHour(cls.schedule_hour)} {t('hr')}</td>
                    <td>
                      {cls.status === 'approved' ? (
                        <span className="approved-text">{t('HOURSAPPROVED')}</span>
                      ) : (
                        <button onClick={() => handleApprove(cls.classid)}>
                          {t('confirmteach')}
                        </button>
                      )}
                    </td>
                    <td className={cls.payout === 'pending' ? 'payout-pending' : 'payout-paid'}>
                      {cls.payout === 'pending' ? t('Pending') : t('Paid')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination-container">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="page-button"
              >
                {t('Previous')}
              </button>
              {Array.from({ length: Math.ceil(filteredClasses.length / itemsPerPage) }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredClasses.length / itemsPerPage)}
                className="page-button"
              >
                {t('Next')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(TeacherHours, ['teacher']);
