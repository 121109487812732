import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendarAlt, faBriefcase, faBook, faEnvelope, faPhone, faHome, faDollarSign, faCheck, faTimes, faGraduationCap, faArrowCircleRight, faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import CustomPieChart from '../../components/CustomPieChart';
import MonthlyHoursTrends from '../../components/MonthlyHoursTrends';
import './Teacher.css';
import getUserIdFromToken from '../../Utils/authUtils';
import { useTranslation } from 'react-i18next';


function TeacherDashboard() {
  const { t, i18n } = useTranslation();
  const userId = getUserIdFromToken(); 
  const [teacher, setTeacher] = useState(null);
  const [classGroups, setClassGroups] = useState([]);
  const [error, setError] = useState(null);
  const [totalMonthlyHours, setTotalMonthlyHours] = useState(0);
  const [popupData, setPopupData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year:'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };
  
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')} ${t('hr')}`;
  };
  
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };
  
  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    try {
      const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value, 10));
      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hr')}`;
    } catch (error) {
      console.error('Invalid schedule hour value:', scheduleHourString);
      return 'Invalid Time';
    }
  };

  const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      const daysOfWeek = t('daysOfWeek', { returnObjects: true });
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return t('invalidDay');
    }
  };
  

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data) {
          const teacherData = response.data;
          setTeacher({
            ...teacherData,
            bachelorDegree: teacherData.bachelor_degree,
            masterDegree: teacherData.master_degree,
            doctoralDegree: teacherData.doctoral_degree,
            photourl: teacherData.photourl || 'https://via.placeholder.com/150',
          });
        } else {
          setError(t('noTeacherDataFound'));
        }
      } catch (error) {
        console.error('Error fetching teacher data:', error);
        setError(t('failedToFetchTeacherData'));
      }
    };

    const fetchTotalMonthlyHours = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classes/teacher/totalhours/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const { totalHours } = response.data;
        setTotalMonthlyHours(totalHours);
      } catch (error) {
        console.error('Error fetching total monthly hours:', error);
        setError(t('failedToFetchTotalMonthlyHours'));
      }
    };

    const fetchClassGroups = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/class-groups/teacher/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setClassGroups(response.data);
      } catch (error) {
        console.error('Error fetching class groups:', error);
        setError(t('failedToFetchClassGroups'));
      }
    };

    const fetchRegistrationsCount = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setRegistrationsCount(response.data);
      } catch (error) {
        console.error('Error fetching registrations count:', error);
        setError(t('failedToFetchRegistrationsCount'));
      }
    };

    fetchTeacherData();
    fetchClassGroups();
    fetchRegistrationsCount();
    fetchTotalMonthlyHours();
  }, [userId, API_URL, t]);

  const handleScheduleClick = (schedule) => {
    setPopupData(schedule);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find(rc => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  if (error) return <p>{error}</p>;
  if (!teacher) return <p>{t('loading')}</p>;

  const today = new Date().setHours(0, 0, 0, 0);
  const newClassGroups = classGroups.filter(classGroup => new Date(classGroup.start_date) >= today);
  const allClassGroups = classGroups.filter(classGroup => new Date(classGroup.start_date) < today);

  const sortedNewClassGroups = [...newClassGroups].sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
  const sortedAllClassGroups = [...allClassGroups].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

  return (
    <div className="teacher-dashboard-container-t">
      <Sidebar />
      <Header />
      <div className="teacher-header">
        
        <div className="teacher-header-info">
            <h1><FontAwesomeIcon icon={faUser} className="icon" />  {teacher.firstname} {teacher.lastname} ({teacher.nickname})</h1>
            <h6><FontAwesomeIcon icon={faCalendarAlt} className="icon" />  {t('dateJoined')}: {formatDate(teacher.createdat)}</h6>
            <h6><FontAwesomeIcon icon={faBriefcase} className="icon" />  {t('position')}: {teacher.role}</h6>
            <h6><FontAwesomeIcon icon={faBook} className="icon" />  {t('subjects')}: {Array.isArray(teacher.specialize_subjects) ? teacher.specialize_subjects.join(', ') : teacher.specialize_subjects}</h6>
            <p><FontAwesomeIcon icon={faArrowCircleRight} className="icon" /> {teacher.schedule}</p>
        </div>
        <div className="teacher-right-header">
          <span className={`status ${teacher.online ? 'online' : 'offline'}`}>
            {teacher.online ? t('online') : t('offline')}
          </span>
          <p>{t('lastSignedIn')}: {teacher.last_signed_in ? formatDateTime(teacher.last_signed_in) : 'N/A'}</p>
        </div>
      </div>
       
      
      <div className="teacher-dashboard-content">
        <div className="teacher-profile">
          <h2>{t('profile')}</h2>
          <img src={teacher.photourl} alt={`${teacher.firstname} ${teacher.lastname}`} className="teacher-image2" />
            <p><FontAwesomeIcon icon={faEnvelope} className="icon" /><strong>{t('email')}:</strong> {teacher.email}</p>
            <p><FontAwesomeIcon icon={faPhone} className="icon" /><strong>{t('phone')}:</strong> {teacher.phone}</p>
            <p><FontAwesomeIcon icon={faHome} className="icon" /><strong>{t('address')}:</strong> {teacher.address}</p>
            <p><FontAwesomeIcon icon={faBirthdayCake} className="icon" /><strong>{t('dob')}:</strong> {formatDate(teacher.date_of_birth)}</p>
            <p><FontAwesomeIcon icon={faDollarSign} className="icon" /><strong>{t('salary')}:</strong> {teacher.salary} {t('baht')}</p>
            <p><FontAwesomeIcon icon={teacher.isfulltime ? faCheck : faTimes} className="icon" /><strong>{t('fullTime')}:</strong> {teacher.isfulltime ? t('yes') : t('no')}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} className="icon" /><strong>{t('bachelorDegree')}:</strong> {teacher.bachelorDegree}, {teacher.bachelor_school}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} className="icon" /><strong>{t('masterDegree')}:</strong> {teacher.masterDegree}, {teacher.master_school}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} className="icon" /><strong>{t('doctoralDegree')}:</strong> {teacher.doctoralDegree}, {teacher.doctoral_school}</p>
            <p><FontAwesomeIcon icon={faBook} className="icon" /><strong>{t('subjectsTaught')}:</strong> {Array.isArray(teacher.specialize_subjects) ? teacher.specialize_subjects.join(', ') : teacher.specialize_subjects}</p>
        </div>
        <div className="teacher-efficiency">
          <h2>{t('totalMonthlyHours')}</h2>
          <div className="total-hour"><h3>{formatMinutesToHours(totalMonthlyHours)}</h3></div>
          <h2>{t('studentRating')}</h2>
          <CustomPieChart />
        </div>
        <div className="today-classes-t">
          <h2>{t('monthlyHoursTrends')}</h2>
          <MonthlyHoursTrends teacherId={userId} />
        </div>
      </div>


      <div className="all-classes">
        <h2>{t('newCourses')}</h2>
        <table className="class-table">
          <thead>
            <tr>
              <th>{t('classCode')}</th>
              <th>{t('subjects')}</th>
              <th>{t('schedule')}</th>
              <th>{t('startDate')}</th>
              <th>{t('endDate')}</th>
              <th>{t('totalHours')}</th>
              <th>{t('bookDetails')}</th>
              <th>{t('type')}</th>
              <th>{t('registerCapacity')}</th>
              <th>{t('registeredStudents')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedNewClassGroups.map((cls, index) => (
              <tr key={index}>
                <td><Link to={`/classdetails/${cls.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                   {cls.class_code}
                </Link></td>
                <td><strong>{cls.subjects.map(subject => subject.title).join(', ')}</strong></td>
                <td>
                  <button onClick={() => handleScheduleClick(cls.classes)} className="schedule-button">
                    {t('schedule')}
                  </button>
                </td>
                <td>{formatDate(cls.start_date)}</td>
                <td>{formatDate(cls.end_date)}</td>
                <td>{formatScheduleHour(cls.total_hours)}</td>
                <td>{cls.book_details_materials}</td>
                <td>{cls.isprivate ? t('private') : t('group')}</td>
                <td>{cls.register_capacity}</td>
                <td>{getRegisteredStudents(cls.groupid)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="class-card-t">
    {sortedNewClassGroups.map((cls, index) => (
      <div key={index} className="class-card-t">
        <div className="class-card-header">
          <Link to={`/classdetails/${cls.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
            {cls.class_code}
          </Link>
        </div>
        <div>{cls.subjects.map(subject => subject.title).join(', ')}</div>
        <button onClick={() => handleScheduleClick(cls.classes)} className="schedule-button">
          {t('schedule')}
        </button>
        <div>{formatDate(cls.start_date)} - {formatDate(cls.end_date)}</div>
        <div>{t('totalHours')}: {formatScheduleHour(cls.total_hours)}</div>
        <div>{t('bookDetails')}: {cls.book_details_materials}</div>
        <div>{t('type')}: {cls.isprivate ? t('private') : t('group')}</div>
        <div>{t('registerCapacity')}: {cls.register_capacity}</div>
        <div>{t('registeredStudents')}: {getRegisteredStudents(cls.groupid)}</div>
      </div>
    ))}
  </div>
      <div className="all-classes">
        <h2>{t('allCourses')}</h2>
        <table className="class-table">
          <thead>
            <tr>
              <th>{t('classCode')}</th>
              <th>{t('subjectName')}</th>
              <th>{t('schedule')}</th>
              <th>{t('startDate')}</th>
              <th>{t('endDate')}</th>
              <th>{t('totalHours')}</th>
              <th>{t('bookDetails')}</th>
              <th>{t('type')}</th>
              <th>{t('registerCapacity')}</th>
              <th>{t('registeredStudents')}</th>
            </tr>
          </thead>
          <tbody>
            {sortedAllClassGroups.map((cls, index) => (
              <tr key={index}>
                <td><Link to={`/classdetails/${cls.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                   {cls.class_code}
                </Link></td>
                <td><strong>{cls.subjects.map(subject => subject.title).join(', ')}</strong></td>
                <td>
                  <button onClick={() => handleScheduleClick(cls.classes)} className="schedule-button">
                    {t('schedule')}
                  </button>
                </td>
                <td>{formatDate(cls.start_date)}</td>
                <td>{formatDate(cls.end_date)}</td>
                <td>{formatScheduleHour(cls.total_hours)}</td>
                <td>{cls.book_details_materials}</td>
                <td>{cls.isprivate ? t('private') : t('group')}</td>
                <td>{cls.register_capacity}</td>
                <td>{getRegisteredStudents(cls.groupid)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="class-cardt">
    {sortedAllClassGroups.map((cls, index) => (
      <div key={index} className="class-card-t">
        <div className="class-card-header">
          <Link to={`/classdetails/${cls.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
            {cls.class_code}
          </Link>
        </div>
        <div>{cls.subjects.map(subject => subject.title).join(', ')}</div>
        <button onClick={() => handleScheduleClick(cls.classes)} className="schedule-button">
          {t('schedule')}
        </button>
        <div>{formatDate(cls.start_date)} - {formatDate(cls.end_date)}</div>
        <div>{t('totalHours')}: {formatScheduleHour(cls.total_hours)}</div>
        <div>{t('bookDetails')}: {cls.book_details_materials}</div>
        <div>{t('type')}: {cls.isprivate ? t('private') : t('group')}</div>
        <div>{t('registerCapacity')}: {cls.register_capacity}</div>
        <div>{t('registeredStudents')}: {getRegisteredStudents(cls.groupid)}</div>
      </div>
    ))}
  </div>
      <Modal isOpen={!!popupData} onClose={closePopup}>
        <h2>{t('scheduleDetails')}</h2>
        <table className="schedule-table">
          <thead>
            <tr>
              <th>{t('subjectName')}</th>
              <th>{t('teacherName')}</th>
              <th>{t('day')}</th>
              <th>{t('date')}</th>
              <th>{t('time')}</th>
              <th>{t('hours')}</th>
              <th>{t('classroomNumber')}</th>
            </tr>
          </thead>
          <tbody>
            {popupData && popupData.map((slot, index) => (
              <tr key={index}>
                <td>{slot.subject_name}</td>
                <td>{slot.teacher_name}</td>
                <td>{getDayOfWeek(slot.date)}</td>
                <td>{formatDate(slot.date)}</td>
                <td>{formatTime(slot.schedule_time)}</td>
                <td>{formatMinutesToHours(slot.schedule_hour)}</td>
                <td>{slot.classroom_number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </div>
  );
}

export default withRoleAccess(TeacherDashboard, ['superadmin', 'teacher']);
