import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faGraduationCap, faUser, faPhoneAlt, faSchool, faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { QRCodeCanvas } from 'qrcode.react';
import getUserIdFromToken from '../../Utils/authUtils';
import ReceiptModal from '../Accounting/ReceiptModal';
import Modal from '../../components/Modal';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import './Student.css';
import { useTranslation } from 'react-i18next';

function StudentDashboard() {
  const { t, i18n } = useTranslation(); // Use translation hook
  const userId = getUserIdFromToken();
  const [student, setStudent] = useState(null);
  const [error, setError] = useState(null);
  const [billingHistory, setBillingHistory] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [popupData, setPopupData] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year:'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };
  
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      const daysOfWeek = t('daysOfWeek', { returnObjects: true });
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return t('invalidDay');
    }
  };
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const studentData = response.data;
        setStudent({
          ...studentData,
          photourl: studentData.photourl || 'https://via.placeholder.com/150',
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
        setError('Failed to fetch student data.');
      }
    };

    const fetchStudentInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/student-info/student/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentInfo(response.data);
      } catch (error) {
        console.error('Error fetching student info:', error);
      }
    };


    const fetchBillingHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/user`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const { registrations } = response.data;
        setBillingHistory(registrations);
      } catch (error) {
        console.error('Error fetching billing history:', error);
      }
    };

    fetchStudentData();
    fetchStudentInfo();
    fetchBillingHistory();
  }, [userId, API_URL]);

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);

    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;

    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }

    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const handleReceiptClick = (registration) => {
    setSelectedRegistration(registration);
    setShowReceiptModal(true);
  };

  const closeReceiptModal = () => {
    setShowReceiptModal(false);
    setSelectedRegistration(null);
  };

  const openSchedulePopup = (data) => {
    setPopupData(data);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const sortByDateAscending = (schedule) => {
    return schedule.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const filterFutureClasses = (schedule) => {
    const now = new Date();
    return schedule.filter(cls => new Date(cls.date) >= now);
  };

  const filteredAndSortedBillingHistory = billingHistory.map(enrollment => ({
    ...enrollment,
    schedule: sortByDateAscending(filterFutureClasses(enrollment.schedule))
  })).filter(enrollment => enrollment.schedule.length > 0);

  // Pagination logic
  const paginatedSchedule = filteredAndSortedBillingHistory
    .flatMap(enrollment => enrollment.schedule.map(schedule => ({ ...schedule, class_code: enrollment.class_code })))
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(filteredAndSortedBillingHistory.flatMap(enrollment => enrollment.schedule).length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;

  return (
    <div>
            <div className="student-dashboard-container">
      <Sidebar />
      <Header />
      <div>
      <div className="student-dashboard-content">
      <div className="student-profile">
      <h2>Student Card</h2>
          <div className="student-id-card">
              <img src={student.photourl} alt={`${student.firstname} ${student.lastname}`} />
              <div className="student-name">{student.firstname} {student.lastname}</div>
              <div className="student-year">{student.schoolid}</div>
              <QRCodeCanvas value={student.schoolid} size={100} className="student-qr" />
          </div>
      </div>

        <div className="today-classes">
        <h2>{t('profile')}</h2>
        <p><FontAwesomeIcon icon={faEnvelope} className="icon" /><strong>{t('email')}:</strong> {student.email}</p>
            <p><FontAwesomeIcon icon={faPhone} className="icon" /><strong>{t('phone')}:</strong> {student.phone}</p>
            <p><FontAwesomeIcon icon={faHome} className="icon" /><strong>{t('address')}:</strong> {student.address}</p>
            <p><FontAwesomeIcon icon={faBirthdayCake} className="icon" /><strong>{t('dob')}:</strong> {formatDate(student.date_of_birth)}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} className="icon" /><strong>{t('currentEducationLevel')}:</strong> {student.currenteducationlevel}</p>
            <p><FontAwesomeIcon icon={faUser} className="icon" /><strong>{t('guardianName')}:</strong> {student.guardianname}</p>
            <p><FontAwesomeIcon icon={faPhoneAlt} className="icon" /><strong>{t('guardianContact')}:</strong> {student.guardiancontact}</p>
            <p><FontAwesomeIcon icon={faSchool} className="icon" /><strong>{t('schoolName')}:</strong> {student.schoolname}</p>
            <table>
            <tbody>
              {studentInfo.map((info, index) => (
                <tr key={index}>
                  <td>{formatDate(info.created_at)} - {info.info_text} ({info.created_by_firstname})</td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>

        <div className="student-attendance">
          <h2>{t('mycourse')}</h2>
          <div className="billing-history-cards">
            {billingHistory.map((enrollment, index) => (
              <div key={index} className="billing-card">
                <div className="billing-card-content">
                  <div className="billing-card-header">
                    {enrollment.class_code}
                  </div>
                  <div className="billing-card-hours">
                    {enrollment.total_hours} {t('hours')} 
                  </div>
                </div>
              </div>
            ))}

        </div>
        
        </div>
        
      </div>
      <div className="student-list-box-container">
        <div className="student-list-box">
           <h2>{t('classSchedule')}</h2>
          <table className="classtable">
          <thead>
              <tr>
                <th>{t('date')}</th>
                <th>{t('classCode')}</th>
                <th>{t('subject')}</th>
                <th>{t('teacher')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedSchedule.map((cls, index) => (
                <tr key={index}>
                  <td>{formatDate(cls.date)}</td>
                  <td>{cls.class_code}</td>
                  <td>{cls.subject}</td>
                  <td>{cls.teacher}</td>
                  <td>{formatTime(cls.time)}</td>
                  <td>{cls.hours} {t('hr')}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {paginatedSchedule.map((cls, index) => (
            <div key={index} className="class-card">
              <div className="class-card-date">{formatDate(cls.date)}</div>
              <div className="class-card-details">
                <div className="class-card-item">{cls.class_code}</div>
                <div className="class-card-item"> {cls.subject}</div>
                <div className="class-card-item"><strong>{t('teacher')}:</strong> {cls.teacher}</div>
                <div className="class-card-item"><strong>{t('time')}:</strong> {formatTime(cls.time)}</div>
                <div className="class-card-item"><strong>{t('hours')}:</strong> {cls.hours}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button key={index} onClick={() => handlePageChange(index + 1)} disabled={currentPage === index + 1}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
        <div className="student-list-box">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            locale={i18n.language === 'th' ? thLocale : 'en'} // Apply the locale
            events={filteredAndSortedBillingHistory.flatMap((enrollment) =>
              enrollment.schedule.map((cls) => {
                const formattedStartTime = cls.time.slice(0, 5);
                const startDateTime = `${cls.date.split('T')[0]}T${formattedStartTime}:00`;
                const formattedEndTime = calculateEndTime(formattedStartTime, cls.hours);
                const endDateTime = `${cls.date.split('T')[0]}T${formattedEndTime}:00`;

                return {
                  title: `${enrollment.class_code} - ${enrollment.subject}`,
                  start: startDateTime,
                  end: endDateTime,
                  extendedProps: {
                    teachers: enrollment.teachers.map((teacher) => teacher.teacher_name).join(', '),
                    time: formattedStartTime,
                    hours: cls.hours,
                  }
                };
              })
            )}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek'
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            height="auto"
            slotMinTime="06:00:00"
            slotMaxTime="21:00:00"
          />
        </div>
      </div>

      <div className="all-classes">
        <h2>{t('currentEnrollments')}</h2>
        <div className="student-card-container">
          {billingHistory.map((enrollment, index) => (
            <div key={index} className="student-card">
              <div className="student-card-header">
                <Link to={`/classdetailsstudent/${enrollment.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                  <button className="student-class-code-button">{enrollment.class_code}</button>
                </Link>
              </div>
              <div className="student-card-body">
                <div className="student-card-subjects">
                  {enrollment.subjects.map((subject, idx) => (
                    <div key={idx}>{subject.title}</div>
                  ))}
                </div>
                <div className="student-card-schedule">
                  <button onClick={() => openSchedulePopup(sortByDateAscending(enrollment.schedule))}>{t('viewSchedule')}</button>
                </div>
                <div className="student-card-hours">
                  <strong>{t('totalHours')}:</strong> {enrollment.total_hours}
                </div>
              </div>
            </div>
          ))}
        </div>


      </div>

        <div className="billing-history">
          <h2>{t('billingHistory')}</h2>
          <table>
            <thead>
              <tr>
                <th>{t('receiptNo')}</th>
                <th>{t('billingDate')}</th>
                <th>{t('amount')}</th>
              </tr>
            </thead>
            <tbody>
              {billingHistory.map((bill, index) => (
                <tr key={index}>
                  <td>
                    <span
                      className="link-button2"
                      onClick={() => handleReceiptClick(bill)}
                      style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                    >
                      {bill.invoiceno}
                    </span>
                  </td>
                  <td>{formatDate(bill.date)}</td>
                  <td>{bill.final_price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      {showReceiptModal && selectedRegistration && (
        <ReceiptModal
          show={showReceiptModal}
          registration={selectedRegistration}
          onClose={closeReceiptModal}
        />
      )}

      {popupData && (
        <Modal isOpen={!!popupData} onClose={closePopup}>
          <h2>{t('scheduleDetails')}</h2>
          <table className="schedule-table">
            <thead>
              <tr>
                <th>{t('subjectName')}</th>
                <th>{t('teacherName')}</th>
                <th>{t('day')}</th>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
              {popupData.map((slot, index) => (
                <tr key={index}>
                  <td>{slot.subject}</td>
                  <td>{slot.teacher}</td>
                  <td>{getDayOfWeek(slot.date)}</td>
                  <td>{formatDate(slot.date)}</td>
                  <td>{formatTime(slot.time)}</td>
                  <td>{slot.hours}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
      </div>
    </div>

  );
}

export default withRoleAccess(StudentDashboard, ['student']);
