import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationTH from './locales/th/translation.json';

// The translations
const resources = {
  en: {
    translation: translationEN,
  },
  th: {
    translation: translationTH,
  },
};

i18n
  .use(LanguageDetector) // Detects the language from browser settings, local storage, etc.
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources,
    lng: 'th', // Sets the initial language
    fallbackLng: 'en', // Uses English if the detected language is not available
    debug: false,
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
    detection: {
      order: ['querystring', 'localStorage', 'cookie', 'navigator', 'htmlTag'],
      caches: ['localStorage', 'cookie'], // Caches the detected language in localStorage and cookie
    },
  });

export default i18n;
