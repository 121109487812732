import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileAlt, faSort, faSortUp, faSortDown, faCheck, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import InvoiceModal from './InvoiceModal';
import ReceiptModal from './ReceiptModal';
import RefundModal from './RefundModal';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import './Accounting.css';

const formatDate = (dateString, locale) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

const formatNumber = (number, locale) => {
  if (isNaN(number)) return '0.00';
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
};

const Accounting = () => {
  const { t, i18n } = useTranslation();
  const [registrations, setRegistrations] = useState([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: '',
    paymentMethod: '',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);


  const API_URL = process.env.REACT_APP_API_URL;

  const fetchRegistrations = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = await Promise.all(response.data.map(async (reg) => {
        const statusResponse = await axios.get(`${API_URL}/registrations/confirm-status/${reg.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return {
          ...reg,
          confirmation_status: statusResponse.data.confirmation_status
        };
      }));
      setRegistrations(data);
      setFilteredRegistrations(data);
  
      const methods = [...new Set(data.map(reg => reg.payment_method))];
      setPaymentMethods(methods);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    }
  }, [API_URL]);
  
  useEffect(() => {
    fetchRegistrations();
  }, [fetchRegistrations]);
  

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    let filtered = registrations;

    if (filters.fromDate) {
      filtered = filtered.filter(registration => new Date(registration.date) >= new Date(filters.fromDate));
    }

    if (filters.toDate) {
      filtered = filtered.filter(registration => new Date(registration.date) <= new Date(filters.toDate));
    }

    if (filters.paymentMethod) {
      filtered = filtered.filter(registration => registration.payment_method === filters.paymentMethod);
    }

    setFilteredRegistrations(filtered);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedRegistrations = filteredRegistrations.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedRegistrations.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const calculateTotals = () => {
    return filteredRegistrations.reduce(
      (totals, registration) => {
        totals.price += parseFloat(registration.price) || 0;
        totals.discount += parseFloat(registration.discount) || 0;
        totals.vat += parseFloat(registration.vat) || 0;
        totals.finalPrice += parseFloat(registration.final_price) || 0;
        return totals;
      },
      { price: 0, discount: 0, vat: 0, finalPrice: 0 }
    );
  };

  const totals = calculateTotals();

  // Prepare CSV data
  const csvData = [
    ...filteredRegistrations,
    {
      invoiceno: 'TOTAL',
      date: '',
      student_name: '',
      class_code: '',
      price: totals.price,
      discount: totals.discount,
      vat: totals.vat,
      final_price: totals.finalPrice,
      payment_method: '',
      memo: '',
      admin_id: ''
    }
  ];

  const handleInvoiceClick = (registration) => {
    setSelectedRegistration(registration);
    setShowInvoiceModal(true);
  };

  const handleReceiptClick = (registration) => {
    setSelectedRegistration(registration);
    setShowReceiptModal(true);
  };

  const handleRefundClick = (registration) => {
    setSelectedRegistration(registration);
    setShowRefundModal(true);
  };
  

  const handleConfirmClick = async (registrationId) => {
    try {
      confirmAlert({
        title: t('confirm_registration'),
        message: t('Are you sure you want to confirm this registration?'),
        buttons: [
          {
            label: t('yes'),
            onClick: async () => {
              const token = localStorage.getItem('token');
              await axios.post(`${API_URL}/registrations/confirm`, 
                { registrationId }, 
                {
                  headers: {
                    Authorization: `Bearer ${token}`
                  }
                }
              );
              confirmAlert({
                title: t('success'),
                message: t('paymentConfirmed'),
                buttons: [
                  {
                    label: t('ok'),
                    onClick: () => fetchRegistrations(), // Re-fetch the registrations data
                  }
                ]
              });
            }
          },
          {
            label: t('no'),
          }
        ]
      });
    } catch (error) {
      console.error('Error confirming registration:', error.response || error.message);
    }
  };


  return (
    <div className="accounting-container">
      <Sidebar />
      <Header />
      <h1>{t('confirm_registration')}</h1>
      <div className="filter-container">
        <label>
          {t('fromDate')}:
          <input type="date" name="fromDate" value={filters.fromDate} onChange={handleFilterChange} />
        </label>
        <label>
          {t('toDate')}:
          <input type="date" name="toDate" value={filters.toDate} onChange={handleFilterChange} />
        </label>
        <label>
          {t('paymentMethod')}:
          <select name="paymentMethod" value={filters.paymentMethod} onChange={handleFilterChange}>
            <option value="">{t('all')}</option>
            {paymentMethods.map(method => (
              <option key={method} value={method}>{method}</option>
            ))}
          </select>
        </label>
        <button onClick={applyFilters} className="filter-button">{t('applyFilters')}</button>
        <CSVLink data={csvData} filename={"registrations.csv"} className="btn btn-success export-button" target="_blank">
          <FontAwesomeIcon icon={faFileExcel} /> {t('exportCSV')}
        </CSVLink>
      </div>
      <div className="pagination-container">
        <button
          className={`page-button ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => currentPage > 1 && paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {t('prev')}
        </button>
        {Array.from({ length: Math.ceil(filteredRegistrations.length / itemsPerPage) }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => paginate(i + 1)}
            className={`page-button ${i + 1 === currentPage ? 'active' : ''}`}
          >
            {i + 1}
          </button>
        ))}
        <button
          className={`page-button ${currentPage === Math.ceil(filteredRegistrations.length / itemsPerPage) ? 'disabled' : ''}`}
          onClick={() => currentPage < Math.ceil(filteredRegistrations.length / itemsPerPage) && paginate(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredRegistrations.length / itemsPerPage)}
        >
          {t('next')}
        </button>
      </div>
      <table className="accounting-table" id="accounting-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('invoiceno')}>
                {t('invoiceNo')}
                {sortConfig.key === 'invoiceno' ? (
                  sortConfig.direction === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} style={{ color: 'yellow' }} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} style={{ color: 'yellow' }} />
                  )
                ) : (
                  <FontAwesomeIcon icon={faSort} style={{ color: 'yellow' }} />
                )}
              </th>
              <th onClick={() => handleSort('date')}>
                {t('date')}
                {sortConfig.key === 'date' ? (
                  sortConfig.direction === 'asc' ? (
                    <FontAwesomeIcon icon={faSortUp} style={{ color: 'yellow' }} />
                  ) : (
                    <FontAwesomeIcon icon={faSortDown} style={{ color: 'yellow' }} />
                  )
                ) : (
                  <FontAwesomeIcon icon={faSort} style={{ color: 'yellow' }} />
                )}
              </th>
              <th>{t('student')}</th>
              <th>{t('course')}</th>
              <th>{t('price')}</th>
              <th>{t('discount')}</th>
              <th>{t('vat')}</th>
              <th>{t('total')}</th>
              <th>{t('payment')}</th>
              <th>{t('memo')}</th>
              <th>{t('confirm')}</th>
              <th>{t('refund')}</th>
            </tr>
          </thead>
          <tbody>
              {currentItems.map((registration) => (
                <tr key={registration.invoiceno} className={registration.confirmation_status === 'refund' ? 'refund-row' : ''}>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    <Link className="link-button2" onClick={() => handleInvoiceClick(registration)}>
                      {registration.invoiceno}
                    </Link>
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {formatDate(registration.date, i18n.language)}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {registration.student_name}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {registration.class_code}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {formatNumber(registration.price, i18n.language)}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {formatNumber(registration.discount, i18n.language)}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {formatNumber(registration.vat, i18n.language)}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {formatNumber(registration.final_price, i18n.language)}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {registration.payment_method}
                  </td>
                  <td className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                    {registration.memo || 'N/A'}
                  </td>
                  <td>
                    {registration.confirmation_status === 'confirmed' ? (
                      <button onClick={() => handleReceiptClick(registration)} className="receipt-button">
                        <FontAwesomeIcon icon={faFileAlt} /> 
                      </button>
                    ) : registration.confirmation_status === 'refund' ? (
                      <button onClick={() => handleReceiptClick(registration)} className="refund-confirm-button refund-red">
                        <FontAwesomeIcon icon={faFileAlt} />
                      </button>
                    ) : (
                      <button onClick={() => handleConfirmClick(registration.id)} className="acc-confirm-button">
                        <FontAwesomeIcon icon={faCheck} /> 
                      </button>
                    )}
                  </td>
                  <td>
                    {registration.confirmation_status === 'confirmed' && (
                      <button onClick={() => handleRefundClick(registration)} className="refund-button">
                        <FontAwesomeIcon icon={faExchangeAlt} />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
              <tr className="totals-row">
                <td colSpan="4"><strong>{t('total')}</strong></td>
                <td><strong>{formatNumber(totals.price, i18n.language)}</strong></td>
                <td><strong>{formatNumber(totals.discount, i18n.language)}</strong></td>
                <td><strong>{formatNumber(totals.vat, i18n.language)}</strong></td>
                <td><strong>{formatNumber(totals.finalPrice, i18n.language)}</strong></td>
                <td colSpan="2"></td>
                <td colSpan="2"></td>
              </tr>
            </tbody>

        </table>

        <div className="accounting-cards">
          {currentItems.map((registration) => (
            <div key={registration.invoiceno} className={`accounting-card ${registration.confirmation_status === 'refund' ? 'refund-row' : ''}`}>
              <div className="accounting-card-header">
                <span className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                  <Link className="link-button2" onClick={() => handleInvoiceClick(registration)}>
                    {registration.invoiceno}
                  </Link>
                </span>
                <span className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>
                  {formatDate(registration.date, i18n.language)}
                </span>
              </div>
              <div className="accounting-card-content">
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('student')}: {registration.student_name}</div>
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('course')}: {registration.class_code}</div>
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('price')}: {formatNumber(registration.price, i18n.language)}</div>
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('discount')}: {formatNumber(registration.discount, i18n.language)}</div>
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('vat')}: {formatNumber(registration.vat, i18n.language)}</div>
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('total')}: {formatNumber(registration.final_price, i18n.language)}</div>
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('payment')}: {registration.payment_method}</div>
                <div className={registration.confirmation_status === 'refund' ? 'refund-red' : ''}>{t('memo')}: {registration.memo || 'N/A'}</div>
                <div className="buttons">
                  {registration.confirmation_status === 'confirmed' ? (
                    <>
                      <button onClick={() => handleReceiptClick(registration)} className="receipt-button">
                        <FontAwesomeIcon icon={faFileAlt} />
                      </button>
                      <button onClick={() => handleRefundClick(registration)} className="refund-button">
                        <FontAwesomeIcon icon={faExchangeAlt} />
                      </button>
                    </>
                  ) : registration.confirmation_status === 'refund' ? (
                    <button onClick={() => handleReceiptClick(registration)} className="refund-confirm-button refund-red">
                      <FontAwesomeIcon icon={faFileAlt} />
                    </button>
                  ) : (
                    <button onClick={() => handleConfirmClick(registration.id)} className="acc-confirm-button">
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>


      {selectedRegistration && (
        <InvoiceModal
          show={showInvoiceModal}
          onClose={() => setShowInvoiceModal(false)}
          registration={selectedRegistration}
        />
      )}
      {selectedRegistration && (
        <ReceiptModal
          show={showReceiptModal}
          onClose={() => setShowReceiptModal(false)}
          registration={selectedRegistration}
        />
      )}
      {selectedRegistration && (
        <RefundModal
          show={showRefundModal}
          onHide={() => setShowRefundModal(false)}
          registration={selectedRegistration}
          onRefund={fetchRegistrations}  
        />
      )}
      
    </div>
  );
};

export default withRoleAccess(Accounting, ['admin', 'superadmin']);