import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import './Teacher.css';
import { useTranslation } from 'react-i18next';

const generateMonthYearOptions = () => {
  const options = [];
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = currentYear; year >= currentYear - 5; year--) {
    for (let month = 11; month >= 0; month--) {
      if (year === currentYear && month > currentMonth) continue; // Skip future months
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric'
      }).toUpperCase();
      options.push({ value: `${year}-${month + 1}`, label: monthYear });
    }
  }

  return options;
};

const TutorClassLog = () => {
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClasses(response.data);
        setFilteredClasses(response.data); // Initially display all classes
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchClasses();
  }, []);

  // Filter logic based on month/year, status, and payout
  useEffect(() => {
    const filtered = classes.filter(cls => {
      const classDate = new Date(cls.date);
      const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
      const isMonthYearMatch = selectedMonthYear ? classMonthYear === selectedMonthYear : true;
      const isStatusMatch = statusFilter ? cls.status === statusFilter : true;
      const isPayoutMatch = payoutFilter ? cls.payout === payoutFilter : true;
      return isMonthYearMatch && isStatusMatch && isPayoutMatch;
    });

    setFilteredClasses(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  }, [selectedMonthYear, statusFilter, payoutFilter, classes]);

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClasses = filteredClasses.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredClasses.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const monthYearOptions = generateMonthYearOptions();

  return (
    <div className="teacher-hours-container">
      <div className="teacher-hours">
        <h2>{t('tutorclasslogs')}</h2>

        {/* Filters */}
        <div className="filter-container">
          <select
            value={selectedMonthYear}
            onChange={(e) => setSelectedMonthYear(e.target.value)}
            className="filter-select"
          >
            <option value="">{t('All Months/Years')}</option>
            {monthYearOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="filter-select"
          >
            <option value="">{t('classstatuses')}</option>
            <option value="approved">{t('confirmed')}</option>
            <option value="pending">{t('confirmpending')}</option>
          </select>

          <select
            value={payoutFilter}
            onChange={(e) => setPayoutFilter(e.target.value)}
            className="filter-select"
          >
            <option value="">{t('payout')}</option>
            <option value="pending">{t('Pending')}</option>
            <option value="paid">{t('Paid')}</option>
          </select>
        </div>

        {/* Class Log Table */}
        <table>
          <thead>
            <tr>
              <th>{t('No')}</th>
              <th>{t('date')}</th>
              <th>{t('classcode')}</th>
              <th>{t('classid')}</th>
              <th>{t('subject')}</th>
              <th>{t('teacher')}</th>
              <th>{t('time')}</th>
              <th>{t('scheduleHour')}</th>
              <th>{t('confirmteach')}</th>
              <th>{t('payout')}</th>
            </tr>
          </thead>
          <tbody>
            {currentClasses.map((cls, index) => (
              <tr key={cls.classid}>
                <td>{startIndex + index + 1}</td> {/* Row Number */}
                <td>{new Date(cls.date).toLocaleDateString()}</td>
                <td>{cls.class_code}</td>
                <td>{cls.classid}</td>
                <td>{cls.subject_name}</td>
                <td>{cls.teacher_name}</td>
                <td>{cls.schedule_time}</td>
                <td>{cls.schedule_hour}</td>
                <td className={cls.status === 'approved' ? 'status-approved' : 'status-pending'}>
                  {cls.status === 'approved' ? t('HOURSAPPROVED') : t('confirmpending')}
                </td>
                <td className={cls.payout === 'pending' ? 'payout-pending' : 'payout-paid'}>
                  {cls.payout === 'pending' ? t('Pending') : t('Paid')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination-container">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="page-button"
          >
            {t('Previous')}
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="page-button"
          >
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRoleAccess(TutorClassLog, ['superadmin']);
