import React, { useState } from 'react';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChair, faChalkboardTeacher, faSchool, faUserGraduate, faExclamationCircle, faBullhorn, faBook, faBookOpenReader, faBookmark, faFileText } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditDeleteTeacher from './EditDeleteTeacher';
import EditDeleteStudent from './EditDeleteStudent';
import EditDeleteSubject from './EditDeleteSubject';
import FilterTaskTable from '../Class/FilterTaskTable';
import RegistrationList from '../Registration/RegistrationList';
import Classes from '../Class/Classes';
import EditDeleteClassroom from './EditDeleteClassroom';
import AnnouncementAdmin from '../Announcement/AnnouncementAdmin';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import CreateTeacherForm from '../CreateUserform/CreateTeacherForm';
import CreateStudentForm from '../CreateUserform/CreateStudentForm';

function AdminPanel() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('teachers');
  const [showCreateTeacherForm, setShowCreateTeacherForm] = useState(false);
  const [showCreateStudentForm, setShowCreateStudentForm] = useState(false);

  const toggleCreateTeacherForm = () => {
    setShowCreateTeacherForm(!showCreateTeacherForm);
  };

  const toggleCreateStudentForm = () => {
    setShowCreateStudentForm(!showCreateStudentForm);
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="main">
        <div className="tabs-container">
          <button className={`tutor-tab-button ${activeTab === 'teachers' ? 'active' : ''}`} onClick={() => setActiveTab('teachers')}>
            <FontAwesomeIcon icon={faChalkboardTeacher} size="1x" />
            <span>{t('tutors')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'students' ? 'active' : ''}`} onClick={() => setActiveTab('students')}>
            <FontAwesomeIcon icon={faUserGraduate} size="1x" />
            <span>{t('students')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'subjects' ? 'active' : ''}`} onClick={() => setActiveTab('subjects')}>
            <FontAwesomeIcon icon={faSchool} size="1x" />
            <span>{t('subjects')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'classrooms' ? 'active' : ''}`} onClick={() => setActiveTab('classrooms')}>
            <FontAwesomeIcon icon={faChair} size="1x" />
            <span>{t('classrooms')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'class' ? 'active' : ''}`} onClick={() => setActiveTab('class')}>
            <FontAwesomeIcon icon={faBookmark} size="1x" />
            <span>{t('createCourse')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'courses' ? 'active' : ''}`} onClick={() => setActiveTab('courses')}>
            <FontAwesomeIcon icon={faBook} size="1x" />
            <span>{t('manageCourses')}</span>
          </button>
          <Link to="/allclasses" className={`tutor-tab-button ${activeTab === 'classes' ? 'active' : ''}`} onClick={() => setActiveTab('classes')}>
            <FontAwesomeIcon icon={faBookOpenReader} size="1x" />
            <span>{t('classes')}</span>
          </Link>
          <button className={`tutor-tab-button ${activeTab === 'registrationlist' ? 'active' : ''}`} onClick={() => setActiveTab('registrationlist')}>
            <FontAwesomeIcon icon={faFileText} size="1x" />
            <span>{t('registrationlist')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'annoucements' ? 'active' : ''}`} onClick={() => setActiveTab('annoucements')}>
            <FontAwesomeIcon icon={faBullhorn} size="1x" />
            <span>{t('announcements')}</span>
          </button>
        </div>
        <div className="main-content">
          <div className="warning">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> <strong>{t('warning')}</strong>: {t('warningMessage')}</p>
          </div>
          <div>
            {activeTab === 'teachers' && !showCreateTeacherForm && (
              <EditDeleteTeacher onCreateNewTeacher={toggleCreateTeacherForm} />
            )}
            {activeTab === 'teachers' && showCreateTeacherForm && (
              <CreateTeacherForm onClose={toggleCreateTeacherForm} />
            )}
            {activeTab === 'students' && !showCreateStudentForm && (
              <EditDeleteStudent onCreateNewStudent={toggleCreateStudentForm} />
            )}
            {activeTab === 'students' && showCreateStudentForm && (
              <CreateStudentForm onClose={toggleCreateStudentForm} />
            )}
            {activeTab === 'class' && <Classes />}
            {activeTab === 'courses' && <FilterTaskTable />}
            {activeTab === 'subjects' && <EditDeleteSubject />}
            {activeTab === 'classrooms' && <EditDeleteClassroom />}
            {activeTab === 'annoucements' && <AnnouncementAdmin />}
            {activeTab === 'registrationlist' && <RegistrationList />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRoleAccess(AdminPanel, ['admin', 'superadmin']);
