import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Subject.css';
import SubjectCard from './SubjectCard';
import SubjectDetailsModal from './SubjectDetailsModal'; 
import { useTranslation } from 'react-i18next';

function Subject() {
    const { t } = useTranslation();
    const [subjects, setSubjects] = useState({});
    const [activeGroup, setActiveGroup] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL;

    const handleViewDetailsClick = (subject) => {
        setSelectedSubject(subject);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSubject(null);
    };
      
    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${API_URL}/subjects`);
                const groupedSubjects = groupBySubjectGroup(response.data);
                setSubjects(groupedSubjects);
                setActiveGroup(Object.keys(groupedSubjects)[0]); // Set first group as active by default
                setLoading(false);
            } catch (error) {
                console.error('Error fetching subjects:', error);
                setError(t('errorFetchingSubjects'));
                setLoading(false);
            }
        };

        fetchSubjects();
    }, [API_URL, t]);

    const groupBySubjectGroup = (subjectsArray) => {
        return subjectsArray.reduce((groupedSubjects, subject) => {
            const group = subject.subjectgroup;
            if (!groupedSubjects[group]) {
                groupedSubjects[group] = [];
            }
            groupedSubjects[group].push(subject);
            return groupedSubjects;
        }, {});
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="subject-page">
            <Sidebar />
            <Header />
            <div className="subject-tabs">
                {Object.keys(subjects).map(group => (
                    <button
                        key={group}
                        className={`tab-item ${activeGroup === group ? 'active' : ''}`}
                        onClick={() => setActiveGroup(group)}
                    >
                        {group}
                    </button>
                ))}
            </div>
            <div className="subject-container">
                {subjects[activeGroup]?.map(subject => (
                    <SubjectCard 
                        key={subject.subjectid} 
                        subject={subject} 
                        onDetailsClick={handleViewDetailsClick} 
                    />
                ))}
            </div>
            {showModal && (
                <SubjectDetailsModal
                    subject={selectedSubject}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
}

export default Subject;
