import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import PayStubModal from './PayStubModal';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib'; 
import './Teacher.css';
import { useTranslation } from 'react-i18next';

const TeacherPaymentReport = () => {
    const { t } = useTranslation();
    const [paymentReports, setPaymentReports] = useState([]);
    const [selectedPayStub, setSelectedPayStub] = useState(null); // To handle the pay stub data
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchPaymentReports = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/teacher-hours/teacher-payment-report`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setPaymentReports(response.data);
            } catch (error) {
                console.error(t('errorFetchingPaymentReports'), error);
            }
        };
    
        fetchPaymentReports();
    }, [API_URL, t]);

    const formatTotalAmount = (amount) => {
        return typeof amount === 'string' && !isNaN(parseFloat(amount))
            ? parseFloat(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : '0.00';
    };

    const formatDateToDMY = (dateString) => {
        if (!dateString) return '';
    
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };
    

    const numberToWords = (num) => {
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
        const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    
        if (num === 0) return "Zero";
    
        let words = "";
    
        if (Math.floor(num / 1000) > 0) {
            words += numberToWords(Math.floor(num / 1000)) + " Thousand ";
            num %= 1000;
        }
    
        if (Math.floor(num / 100) > 0) {
            words += numberToWords(Math.floor(num / 100)) + " Hundred ";
            num %= 100;
        }
    
        if (num >= 10 && num < 20) {
            words += teens[num - 10] + " ";
        } else if (num >= 20) {
            words += tens[Math.floor(num / 10)] + " ";
            num %= 10;
        }
    
        if (num > 0) {
            words += ones[num] + " ";
        }
    
        return words.trim();
    };
    
    const numberToWordsWithBahtAndSatang = (amount) => {
        // Ensure the amount is a number
        const numericAmount = parseFloat(amount);
        
        if (isNaN(numericAmount)) {
            throw new Error("Invalid amount: amount must be a number");
        }
    
        const [bahtPart, satangPart] = numericAmount.toFixed(2).split('.').map(Number);
        
        let words = numberToWords(bahtPart) + " Baht";
        
        if (satangPart > 0) {
            words += " and " + numberToWords(satangPart) + " Satang";
        }
    
        return words;
    };
    

    const generateTawi50PDF = async (report) => {
        try {
            const existingPdfBytes = await fetch('/images/tawi50.pdf').then(res =>
                res.arrayBuffer()
            );
    
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            const form = pdfDoc.getForm();
    
            // Print all field names (for reference)
            const fields = form.getFields();
            fields.forEach(field => {
                console.log(field.getName());
            });
    
            // Ensure that each value is a string or use an empty string as a fallback
            form.getTextField('name1').setText('PLANN TECH CO., LTD');  // Fixed Company name in English
            form.getTextField('add1').setText('770/122 Patio Pattanakarn 30 Suan Luang Bangkok 10250');  // Fixed Company address in English
            form.getTextField('id1').setText('0103363883993');  // Fixed Company tax ID
    
            form.getTextField('name2').setText(report.teacher_name || '');  // Teacher's name
            form.getTextField('add2').setText(report.address || '');  // Teacher's address
            form.getTextField('id1_2').setText(report.nationalid || '');  // Teacher's national ID
    
            // Check if report.classes exists and is an array before iterating
            if (Array.isArray(report.classes)) {
                report.classes.forEach((cls, index) => {
                    form.getTextField('date1').setText(formatDateToDMY(report.approval_date) || '');
                    form.getTextField(`total${index + 1}`).setText(formatTotalAmount(report.sub_amount || 0)); 
                    form.getTextField(`spec${index + 1}`).setText(formatTotalAmount(report.sum_withholdingtax || 0));
                });
            } else {
                console.error('report.classes is not an array or is undefined');
            }
    
            // Calculate totals (adjust field names as needed)
            const totalSubtotal = report.sub_amount || 0;
            const totalWithholdingTax = report.sum_withholdingtax || 0;
            form.getTextField('date1').setText(report.approval_date || '');
            form.getTextField('pay1.0').setText(formatTotalAmount(totalSubtotal));  
            form.getTextField('tax1.0').setText(formatTotalAmount(totalWithholdingTax));

            form.getTextField('total').setText(numberToWordsWithBahtAndSatang(totalWithholdingTax));

    
            // Check the chk1 and chk8 checkboxes
            form.getCheckBox('chk1').check();
            form.getCheckBox('chk8').check();
    
            const pdfBytes = await pdfDoc.save();
    
            // Download the PDF
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Tawi50_${report.teacher_name}_${report.period}.pdf`;
            link.click();
        } catch (error) {
            console.error('Error generating Tawi50 PDF:', error);
        }
    };
    
    

    const handlePayStubClick = async (report) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/teacher-hours/teacher-payment-report/${report.batch_id}/details`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const classDetails = response.data.map(cls => ({
                classId: cls.class_id,
                rate: cls.hourly_rate,
                hours: cls.hours_worked,
                total: cls.total_amount
            }));

            const payStubData = {
                companyName: "ABC Company",
                companyAddress: "123 Main Street, Anytown, NY 10000.",
                companyPhone: "(555) 555-5555",
                companyTaxId: "51-2322286",
                teacherName: report.teacher_name,
                teacherAddress: report.address, 
                teacherPhone: report.phone, 
                teacherNationalId: report.nationalid,
                payPeriod: report.period,
                payDate: report.approval_date,
                classes: classDetails,
                totalHours: report.total_hours,
                totalPay: report.total_amount,
                withholdingTax: report.sum_withholdingtax,
            };

            setSelectedPayStub(payStubData);
        } catch (error) {
            console.error(t('errorFetchingClassDetails'), error);
        }
    };

    return (
        <div>
            <Sidebar />
            <Header />
            <div className="teacher-hours-container">
                <div className="teacher-hours">
                    <h1>{t('teacherPaymentReport')}</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>{t('approvalDate')}</th>
                                <th>{t('period')}</th>
                                <th>{t('teacherName')}</th>
                                <th>{t('nationalID')}</th>
                                <th>{t('totalHours')}</th>
                                <th>{t('subAmount')}</th> 
                                <th>{t('sumWithholdingTax')}</th> 
                                <th>{t('totalPay')}</th> 
                                <th>{t('approveby')}</th>
                                <th>{t('paystub')}</th>
                                <th>{t('tawi50')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentReports.length > 0 ? (
                                paymentReports.map((report, index) => (
                                    <tr key={index}>
                                        <td>{report.approval_date}</td>
                                        <td>{report.period}</td>
                                        <td>{report.teacher_name}</td>
                                        <td>{report.nationalid}</td>
                                        <td>{report.total_hours}</td>
                                        <td>{formatTotalAmount(report.sub_amount)}</td>
                                        <td>{formatTotalAmount(report.sum_withholdingtax)}</td>
                                        <td>{formatTotalAmount(report.total_amount)}</td>
                                        <td>{report.approval_userid}</td>
                                        <td>
                                            <button onClick={() => handlePayStubClick(report)}>
                                                {t('viewpaystub')}
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={() => generateTawi50PDF(report)}>
                                                {t('Generate Tawi50')}
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10">{t('noPaymentReports')}</td> {/* Adjusted colSpan to 10 for all columns */}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <PayStubModal 
                show={selectedPayStub !== null} 
                onClose={() => setSelectedPayStub(null)} 
                payStubData={selectedPayStub} 
            />
        </div>
    );
};

export default withRoleAccess(TeacherPaymentReport, ['superadmin']);
